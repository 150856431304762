import React from 'react'
import "./submitButton.css"

function SubmitButton (props){

  var button;

  if (props.lockIssue){
    button = <button  className = "lockConstraint"
                      onClick = {props.onClick}>
                      Too Many Locks!
             </button>
  }else if(props.isWaitingforLineups){
    button = <button className = "searching"
                     onClick = {props.onClick}>
                     Searching the Cosmos
              </button>

  }else{
    button = <button className = "ready"
                     onClick = {props.onClick}>
                     Create Lineups
             </button>
  }

  return(button)

}


export default SubmitButton
