const games = [
{
  id: 1,
  site: "dk",
  title: "Draft Kings",
  maxCap: 50000,
  minQB: 4000,
  minRB: 3000,
  minWR: 3000,
  minTE: 2500,
  minFlex: 2500,
  minDST: 2000,
  slates: [{title:"Sun Only",
            criteria: ["SE","SL"]
            },
            {title:"Thu-Mon",
            criteria: ["T","SE","SL","SN","M"]}],
  key: "game"
},
{
  id: 2,
  site: "fd",
  title: "Fan Duel",
  maxCap: 60000,
  minQB: 6000,
  minRB: 4500,
  minWR: 4500,
  minTE: 4000,
  minFlex: 4000,
  minDST: 2500,
  slates: [{title:"Main",
            criteria: ["SE","SL"]},
           {title:"Thu-Mon",
           criteria: ["T","SE","SL","SN","M"]},
           {title:"Sun-Mon",
            criteria: ["SE","SL","SN","M"]},
           {title:"Sun 1pm",
            criteria: ["SE"]},
           {title:"Sun 4pm",
            criteria: ["SL"]}],
  key: "game"
},
{
  id: 3,
  site: "y",
  title: "Yahoo",
  maxCap: 200,
  minQB: 20,
  minRB: 10,
  minWR: 10,
  minTE: 10,
  minFlex: 10,
  minDST: 5,
  slates: [{title:"Main",
            criteria: ["SE","SL","SN"]},
           {title:"Thu-Mon",
           criteria: ["T","SE","SL","SN","M"]},
           {title:"Sun 1pm",
            criteria: ["SE"]},
           {title:"Sun 4pm",
            criteria: ["SL"]},
           {title:"SNF/MNF",
            criteria: ["SN","M"]}],
  key: "game"
}

]

export default games
