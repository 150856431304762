const positionList = [
{
  id: 1,
  title: 'All Players',
  key: 'Position'
},
{
  id: 2,
  title: 'Quarterbacks',
  key: 'Position'
},
{
  id: 3,
  title: 'Running Backs',
  key: 'Position'
},
{
  id: 4,
  title: 'Wide Recievers',
  key: 'Position'
},
{
  id: 5,
  title: 'Tight Ends',
  key: 'Position'
},
{
  id: 6,
  title: 'Flex',
  key: 'Position'
},
{
  id: 7,
  title: 'Defense',
  key: 'Position'
},
{
  id: 8,
  title: 'Lineups',
  key: 'lineup'
}
]

export default positionList
