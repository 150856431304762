import React from 'react';
import BookmarkButton from "./BookmarkButton.js"
import './LineupCard.css';
import locked_img from "../SelectionUnit/assets/lock-active-btn.svg"
import liked_img from "../SelectionUnit/assets/like-active-btn.svg"
import avoid_img from "../SelectionUnit/assets/avoid-active-btn.svg"
import forbid_img from "../SelectionUnit/assets/forbid-active-btn.svg"
import empty_img from "../SelectionUnit/assets/empty.svg"

class LineupCard extends React.Component{

    constructor(props,context){
      super(props,context);

      this.format_name = this.format_name.bind(this);
      this.rating_m = this.rating_m.bind(this);
      this.rating_img = this.rating_img.bind(this);
      this.handleBookmarkChange = this.handleBookmarkChange.bind(this);

      var tot_points = (props.qb.pts * this.rating_m(props.qb.rating)) + (props.rb1.pts * this.rating_m(props.rb1.rating)) + (props.rb2.pts * this.rating_m(props.rb2.rating))
      + (props.wr1.pts* this.rating_m(props.wr1.rating)) + (props.wr2.pts * this.rating_m(props.wr2.rating))
      + (props.wr3.pts * this.rating_m(props.wr3.rating)) + (props.te.pts * this.rating_m(props.te.rating)) + (props.flex.pts * this.rating_m(props.flex.rating))
      + (props.dst.pts * this.rating_m(props.dst.rating))

      tot_points = Math.round(tot_points*10)/10.0

      var tot_salary = props.qb.sal + props.rb1.sal +props.rb2.sal
      + props.wr1.sal + props.wr2.sal + props.wr3.sal + props.te.sal + props.flex.sal + props.dst.sal


      this.state ={
          site: props.site,
          slate: props.slate,
          qb: props.qb,
          rb1: props.rb1,
          rb2: props.rb2,
          wr1: props.wr1,
          wr2: props.wr2,
          wr3: props.wr3,
          te: props.te,
          flex: props.flex,
          dst: props.dst,
          points: tot_points,
          salary: tot_salary,
          isBookmarked: false,
          bookmarkListener: props.bookmarkListener
      }



    }

    componentDidUpdate(prevProps, prevState){

        if(prevProps !== this.props){
                var tot_points = (this.props.qb.pts * this.rating_m(this.props.qb.rating)) + (this.props.rb1.pts * this.rating_m(this.props.rb1.rating)) + (this.props.rb2.pts * this.rating_m(this.props.rb2.rating))
                + (this.props.wr1.pts* this.rating_m(this.props.wr1.rating)) + (this.props.wr2.pts * this.rating_m(this.props.wr2.rating))
                + (this.props.wr3.pts * this.rating_m(this.props.wr3.rating)) + (this.props.te.pts * this.rating_m(this.props.te.rating)) + (this.props.flex.pts * this.rating_m(this.props.flex.rating))
                + (this.props.dst.pts * this.rating_m(this.props.dst.rating))

                tot_points = Math.round(tot_points*10)/10.0

                var tot_salary = this.props.qb.sal + this.props.rb1.sal +this.props.rb2.sal
                + this.props.wr1.sal + this.props.wr2.sal + this.props.wr3.sal + this.props.te.sal + this.props.flex.sal + this.props.dst.sal


                this.setState({
                    site: this.props.site,
                    slate: this.props.slate,
                    qb: this.props.qb,
                    rb1: this.props.rb1,
                    rb2: this.props.rb2,
                    wr1: this.props.wr1,
                    wr2: this.props.wr2,
                    wr3: this.props.wr3,
                    te: this.props.te,
                    flex: this.props.flex,
                    dst: this.props.dst,
                    points: tot_points,
                    salary: tot_salary
                })


        }


    }

    rating_img(rating){
      var img;

      switch(rating){
        case "Like":
        img = liked_img;
        break;
        case "Avoid":
        img = avoid_img;
        break;
        case "Lock":
        img = locked_img;
        break;
        case "Forbid":
        img  = forbid_img;
        break;
        default:
        img = empty_img;
      }

      return img;
    }

    rating_m(rating){
      var m;

      switch(rating){
        case "Like":
            m =1.15;
            break;
        case "Avoid":
            m = .85;
            break;
        default:
            m = 1;
      }

      return m;

    }

   format_name(in_name){
    var short_name = in_name;
    var longest = "Patrick Mahomes"

    if(in_name.length > longest.length){

      var splits = in_name.split(" ")
      short_name = splits[0].charAt(0) + ". " + splits[1]
    }
    return short_name

    }

    handleBookmarkChange = (b) => {

        if (b!==this.state.isBookmarked)
          this.setState({isBookmarked: ! this.state.isBookmarked})
      }

    render (){


      return(
        <div className = "lineupCard">

          <div className = "lineupCardHeader">
            <div className = "lineupCardScore">
              <header>Points</header>
              <p>{this.state.points}</p>
            </div>
            <div className = "lineupCardSalary">
              <header>Salary</header>
              <p>${this.state.salary}</p>
            </div>
            <div className = "lineupCardButtons">
              <header>Bookmark</header>
              <BookmarkButton isBookmarked = {this.state.isBookmarked}
                              handleBookmarkChange = {this.handleBookmarkChange}/>
            </div>
          </div>

          <div className = "lineupCardPlayerRow">
            <div className = "lineupCardPlayerName">
              <header>QB | {this.state.qb.team} {this.state.qb.num}</header>
              <p>{this.format_name(this.state.qb.name)} <img className="ratingImg" src={this.rating_img(this.state.qb.rating)} alt =""/></p>
            </div>
            <div className = "lineupCardPlayerScore">
              <header>Points</header>
              <p>{Math.round(this.state.qb.pts * this.rating_m(this.state.qb.rating) * 10)/10.0}</p>
            </div>
            <div className = 'lineupCardPlayerPrice'>
              <header>Price</header>
              <p>${this.state.qb.sal}</p>
            </div>
          </div>

          <div className = "lineupCardPlayerRow">
            <div className = "lineupCardPlayerName">
              <header>RB1 | {this.state.rb1.team} {this.state.rb1.num}</header>
              <p>{this.format_name(this.state.rb1.name)} <img className="ratingImg" src={this.rating_img(this.state.rb1.rating)} alt =""/></p>
            </div>
            <div className = "lineupCardPlayerScore">
              <header>Points</header>
              <p>{Math.round(this.state.rb1.pts * this.rating_m(this.state.rb1.rating) * 10)/10.0}</p>
            </div>
            <div className = 'lineupCardPlayerPrice'>
              <header>Price</header>
              <p>${this.state.rb1.sal}</p>
            </div>
          </div>

          <div className = "lineupCardPlayerRow">
            <div className = "lineupCardPlayerName">
              <header>RB2 | {this.state.rb2.team} {this.state.rb2.num}</header>
              <p>{this.format_name(this.state.rb2.name)} <img className="ratingImg" src={this.rating_img(this.state.rb2.rating)} alt =""/></p>
            </div>
            <div className = "lineupCardPlayerScore">
              <header>Points</header>
              <p>{Math.round(this.state.rb2.pts * this.rating_m(this.state.rb2.rating) *10)/10.0}</p>
            </div>
            <div className = 'lineupCardPlayerPrice'>
              <header>Price</header>
              <p>${this.state.rb2.sal}</p>
            </div>
          </div>

          <div className = "lineupCardPlayerRow">
            <div className = "lineupCardPlayerName">
              <header>WR1 | {this.state.wr1.team} {this.state.wr1.num}</header>
              <p>{this.format_name(this.state.wr1.name)} <img className="ratingImg" src={this.rating_img(this.state.wr1.rating)} alt =""/></p>
            </div>
            <div className = "lineupCardPlayerScore">
              <header>Points</header>
              <p>{Math.round(this.state.wr1.pts * this.rating_m(this.state.wr1.rating) *10)/10.0}</p>
            </div>
            <div className = 'lineupCardPlayerPrice'>
              <header>Price</header>
              <p>${this.state.wr1.sal}</p>
            </div>
          </div>

          <div className = "lineupCardPlayerRow">
            <div className = "lineupCardPlayerName">
              <header>WR2 | {this.state.wr2.team} {this.state.wr2.num}</header>
              <p>{this.format_name(this.state.wr2.name)} <img className="ratingImg" src={this.rating_img(this.state.wr2.rating)} alt =""/></p>
            </div>
            <div className = "lineupCardPlayerScore">
              <header>Points</header>
              <p>{Math.round(this.state.wr2.pts * this.rating_m(this.state.wr2.rating)*10)/10.0}</p>
            </div>
            <div className = 'lineupCardPlayerPrice'>
              <header>Price</header>
              <p>${this.state.wr2.sal}</p>
            </div>
          </div>

          <div className = "lineupCardPlayerRow">
            <div className = "lineupCardPlayerName">
              <header>WR3 | {this.state.wr3.team} {this.state.wr3.num}</header>
              <p>{this.format_name(this.state.wr3.name)} <img className="ratingImg" src={this.rating_img(this.state.wr3.rating)} alt =""/></p>
            </div>
            <div className = "lineupCardPlayerScore">
              <header>Points</header>
              <p>{Math.round(this.state.wr3.pts * this.rating_m(this.state.wr3.rating)*10)/10.0}</p>
            </div>
            <div className = 'lineupCardPlayerPrice'>
              <header>Price</header>
              <p>${this.state.wr3.sal}</p>
            </div>
          </div>


          <div className = "lineupCardPlayerRow">
            <div className = "lineupCardPlayerName">
              <header>TE | {this.state.te.team} {this.state.te.num}</header>
              <p>{this.format_name(this.state.te.name)} <img className="ratingImg" src={this.rating_img(this.state.te.rating)} alt =""/></p>
            </div>
            <div className = "lineupCardPlayerScore">
              <header>Points</header>
              <p>{Math.round(this.state.te.pts * this.rating_m(this.state.te.rating)*10)/10.0}</p>
            </div>
            <div className = 'lineupCardPlayerPrice'>
              <header>Price</header>
              <p>${this.state.te.sal}</p>
            </div>
          </div>

          <div className = "lineupCardPlayerRow">
            <div className = "lineupCardPlayerName">
              <header>FLEX | {this.state.flex.team} {this.state.flex.pos} {this.state.flex.num}</header>
              <p>{this.format_name(this.state.flex.name)} <img className="ratingImg" src={this.rating_img(this.state.flex.rating)} alt =""/></p>
            </div>
            <div className = "lineupCardPlayerScore">
              <header>Points</header>
              <p>{Math.round(this.state.flex.pts* this.rating_m(this.state.flex.rating) *10)/10.0}</p>
            </div>
            <div className = 'lineupCardPlayerPrice'>
              <header>Price</header>
              <p>${this.state.flex.sal}</p>
            </div>
          </div>

          <div className = "lineupCardPlayerRow">
            <div className = "lineupCardPlayerName">
              <header>TE | {this.state.dst.team}</header>
              <p>{this.format_name(this.state.dst.name)} <img className="ratingImg" src={this.rating_img(this.state.dst.rating)} alt =""/></p>
            </div>
            <div className = "lineupCardPlayerScore">
              <header>Points</header>
              <p>{Math.round(this.state.dst.pts * this.rating_m(this.state.dst.rating) *10)/10.0}</p>
            </div>
            <div className = 'lineupCardPlayerPrice'>
              <header>Price</header>
              <p>${this.state.dst.sal}</p>
            </div>
          </div>

          <div className = "lineupCardFooter">
            <p>{this.state.site + " | " + this.state.slate}</p>
          </div>

        </div>
      )
      }





}

export default LineupCard
