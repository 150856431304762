import React from 'react'
import Player from '../Player.js'
import './LockList.css'

function LockList(props){

  let qb_list = props.players.filter(p => p.pos.includes("QB"))
      qb_list.sort((a,b) => b.pts - a.pts)
      qb_list = qb_list.map(item =>
                           <Player key = {item.id + props.gameSite.site}
                                   id = {item.id}
                                   name = {item.name}
                                   pos = {item.pos}
                                   num = {item.num}
                                   team = {item.team}
                                   opp = {item.opp}
                                   points = {item[props.gameSite.site + "_pts"]}
                                   salary = {item[props.gameSite.site + "_sal"]}
                                   ratingValue = {item.rating}
                                   parentHandleRatingChange = {props.parentHandleRatingChange}/>)

  let rb_list = props.players.filter(p => p.pos.includes("RB"))
      rb_list.sort((a,b) => b.pts - a.pts)
      rb_list = rb_list.map(item =>
                           <Player key = {item.id + props.gameSite.site}
                                   id = {item.id}
                                   name = {item.name}
                                   pos = {item.pos}
                                   num = {item.num}
                                   team = {item.team}
                                   opp = {item.opp}
                                   points = {item[props.gameSite.site + "_pts"]}
                                   salary = {item[props.gameSite.site + "_sal"]}
                                   ratingValue = {item.rating}
                                   parentHandleRatingChange = {props.parentHandleRatingChange}/>)

  let wr_list = props.players.filter(p => p.pos.includes("WR"))
      wr_list.sort((a,b) => b.pts - a.pts)
      wr_list = wr_list.map(item =>
                           <Player key = {item.id + props.gameSite.site}
                                   id = {item.id}
                                   name = {item.name}
                                   pos = {item.pos}
                                   num = {item.num}
                                   team = {item.team}
                                   opp = {item.opp}
                                   points = {item[props.gameSite.site + "_pts"]}
                                   salary = {item[props.gameSite.site + "_sal"]}
                                   ratingValue = {item.rating}
                                   parentHandleRatingChange = {props.parentHandleRatingChange}/>)

  let te_list = props.players.filter(p => p.pos.includes("TE"))
      te_list.sort((a,b) => b.pts - a.pts)
      te_list = te_list.map(item =>
                           <Player key = {item.id + props.gameSite.site}
                                   id = {item.id}
                                   name = {item.name}
                                   pos = {item.pos}
                                   num = {item.num}
                                   team = {item.team}
                                   opp = {item.opp}
                                   points = {item[props.gameSite.site + "_pts"]}
                                   salary = {item[props.gameSite.site + "_sal"]}
                                   ratingValue = {item.rating}
                                   parentHandleRatingChange = {props.parentHandleRatingChange}/>)

  let dst_list = props.players.filter(p => p.pos.includes("DST"))
      dst_list.sort((a,b) => b.pts - a.pts)
      dst_list = dst_list.map(item =>
                           <Player key = {item.id + props.gameSite.site}
                                   id = {item.id}
                                   name = {item.name}
                                   pos = {item.pos}
                                   num = {item.num}
                                   team = {item.team}
                                   opp = {item.opp}
                                   points = {item[props.gameSite.site + "_pts"]}
                                   salary = {item[props.gameSite.site + "_sal"]}
                                   ratingValue = {item.rating}
                                   parentHandleRatingChange = {props.parentHandleRatingChange}/>)

   let full_list = [];

    full_list = full_list.concat(qb_list)
    full_list = full_list.concat(rb_list)
    full_list = full_list.concat(wr_list)
    full_list = full_list.concat(te_list)
    full_list = full_list.concat(dst_list)

    let lockMessage =[]

    if(qb_list.length > 1)
      lockMessage.push("Too many QBs. Unlock " + (qb_list.length - 1))
    if(rb_list.length > 3)
      lockMessage.push("Too many RBs. Unlock " + (rb_list.length - 3))
    if(wr_list.length > 4)
      lockMessage.push("Too many WRs. Unlock " + (wr_list.length - 4))
    if(te_list.length > 2)
      lockMessage.push("Too many TEs. Unlock " + (te_list.length - 2))
    if(dst_list.length > 1)
      lockMessage.push("Too many DSTs. Unlock " + (dst_list.length - 1))

    var flexMessage = ""
    var comma =false;

    if(rb_list.length + wr_list.length + te_list.length > 7){
      flexMessage = "Too Much Flex. Unlock " + (rb_list.length + wr_list.length + te_list.length - 7) + " out of the "
    }
    else if(rb_list.length + wr_list.length > 6){
      flexMessage = "Too Much Flex. Unlock " + (rb_list.length + wr_list.length - 6) + " out of the "
    }
    else if(rb_list.length + te_list.length > 4){
      flexMessage = "Too Much Flex. Unlock " + (rb_list.length + te_list.length - 4) + " out of the "
    }
    else if (wr_list.length + te_list.length > 5){
      flexMessage = "Too Much Flex. Unlock " + (wr_list.length + te_list.length - 5) + " out of the "
    }

    if (flexMessage.length > 0){

        if(rb_list.length > 2){
          flexMessage = flexMessage.concat((rb_list.length) + " RBs")
          comma=true
        }

        if(wr_list.length > 3){
          if(comma)
            flexMessage = flexMessage.concat(", ")

          flexMessage = flexMessage.concat((wr_list.length) + " WRs")
          comma = true
        }
        if(te_list.length > 1){
          if(comma)
            flexMessage = flexMessage.concat(", ")

          flexMessage = flexMessage.concat((te_list.length) + " TEs")
          comma = true
        }

        lockMessage.push(flexMessage + " locked below")
    }

    var capMessage = []
    var capIssue = false;

    let maxCap = props.gameSite.maxCap

    var minReqCap = (qb_list.length >= 1 ? 0 : 1) * props.gameSite.minQB
                  + (rb_list.length >= 2 ? 0 : (2 - rb_list.length)) * props.gameSite.minRB
                  + (wr_list.length >= 3 ? 0 : (3 - wr_list.length)) * props.gameSite.minWR
                  + (te_list.length >= 1 ? 0 : 1) * props.gameSite.minTE
                  + (dst_list.length >= 1 ? 0 : 1) * props.gameSite.minDST
                  + ((rb_list.length > 2 ||
                      wr_list.length > 3 ||
                      te_list.length > 1) ? 0 : 1) * props.gameSite.minFlex

    var lockCap = 0



    if (full_list.length > 0)
      lockCap = full_list.map(item => item.props.salary).reduce((prev, next) => prev + next);

    if(lockCap + minReqCap > maxCap){
       capMessage.push("Too much money on locks!")
       capIssue =true
    }

    capMessage.push("$" + (lockCap.toLocaleString()) + " of $" + maxCap.toLocaleString() + " guarenteed")
    capMessage.push("$" + ((maxCap - lockCap)/(9-full_list.length)).toLocaleString(undefined, {maximumFractionDigits:0}) + " per player remaining")



    var showList;

    switch(props.positionKey){

      case "QB":
        showList = full_list.filter(p => p.props.pos.includes("QB"))
        break;
      case "RB":
        showList = full_list.filter(p => p.props.pos.includes("RB"))
        break;
      case "WR":
        showList = full_list.filter(p => p.props.pos.includes("WR"))
        break;
      case "TE":
        showList = full_list.filter(p => p.props.pos.includes("TE"))
        break;
      case "DST":
        showList = full_list.filter(p => p.props.pos.includes("DST"))
        break;
      case "RB/WR/TE":
        showList = full_list.filter(p=> "RB/WR/TE".includes(p.props.pos))
        break;
      default:
        showList = full_list

    }

    return(

      <div>
        <div className={"capMessage"+ (capIssue ? '--issue' : "")}>
            <header><b>Cap Summary</b></header>
            <ul>
            {capMessage.map(m => <li key ={m}>{m}</li>)}
            </ul>
        </div>
        <div className={"lockMessage" + (lockMessage.length > 0 ? '--active' : "")}>
          <header><b>Too Many Locks!</b></header>
          <ul>
            {lockMessage.map(m => <li  key ={m}>{m}</li>)}
          </ul>
        </div>
        {showList}
      </div>
    )


}


export default LockList
