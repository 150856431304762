import React from "react"

import RatingButtons from "./RatingButtons"
import "./Player.css"

class Player extends React.Component{

  constructor (props){
    super(props);

    this.handleRatingChange = this.handleRatingChange.bind(this);
    this.rankColor = this.rankColor.bind(this);

    var matchup = props.opp.split(" ")

    var multiplier;

    switch(props.ratingValue){
      case "Like":
          multiplier = 1.15;
          break;
      case "Avoid":
          multiplier = .85;
          break;
      default:
          multiplier = 1;
    }

    var short_name = props.name
    var longest = "Darwin Thompson"

    if(props.name.length >= longest.length){

      var splits = props.name.split(" ")
      short_name = splits[0].charAt(0) + ". " + splits[1]
    }

    this.state={
        id: props.id,
        name: props.name,
        showName: short_name,
        pos: props.pos,
        number: props.num,
        team: props.team,
        oppTeam: matchup[0],
        oppRank: matchup[1],
        points: props.points,
        mult: multiplier,
        salary: props.salary,
        ratingValue: props.ratingValue,
    }

  }

  rankColor(oppRank){

    var rank = parseInt(oppRank,10)

    if (rank <= 10){
      return "red"
    }else if (rank <=21) {
      return "black"
    }
      return "green"
  }

  handleRatingChange = (value) => {

    var newRating

    if(value === this.state.ratingValue) {
        newRating = "None"
    }
    else {
      newRating = value
    }

    console.log(newRating)

      var multiplier;

      switch(newRating){
        case "Like":
            multiplier = 1.15;
            break;
        case "Avoid":
            multiplier = .85;
            break;
        default:
            multiplier = 1;
      }

    this.setState({ratingValue: newRating,
                   mult:multiplier});
    this.props.parentHandleRatingChange(newRating, this.state.id);
  }

  render(){



      return(

        <div className="container">
          <div className="top">
            <div className ="name">
              <header>{this.state.team} | {this.state.pos} {this.state.number}</header>
              <p>{this.state.showName}</p>

            </div>

            <div className="salary">
              <header>Price</header>
              <p>${this.state.salary}</p>
            </div>
          </div>

          <div className="bottom">
            <div className="score">
              <header>Points</header>
              <p>{(Number(this.state.points * this.state.mult).toFixed(1) *10)/10}</p>
            </div>
            <div className="matchup">
              <header>Matchup</header>
              <p>{this.state.oppTeam} <sup className={this.rankColor(this.state.oppRank)}>{this.state.oppRank}</sup></p>
            </div>
            <div className ="rateButtons">
              <header>My Rating</header>
              <div>
              <RatingButtons id={this.state.id} ratingValue={this.state.ratingValue} handleRatingChange={this.handleRatingChange}/>
              </div>
            </div>

          </div>
        </div>
)
  }

}

export default Player
