import React from "react"
import onClickOutside from "react-onclickoutside";
import "./DropDown.css"


class DropDown extends React.Component{

  constructor(props){

    super(props)
    this.toggleList = this.toggleList.bind(this);

    this.state ={
          id: props.id,
          listOpen: false,
          headerTitle: props.title,
          dropDownHandler: props.dropDownHandler,
          cssClass: props.cssClass,
          image: props.image
    }
  }

  componentDidUpdate(prevProps, prevState){

    if(prevState.headerTitle !== this.props.title){
        this.setState({headerTitle: this.props.title})
    }
  }

  handleClickOutside(){
    this.setState({
      listOpen: false
    })
  }

  toggleList(){
    this.setState(prevState => ({
      listOpen: !prevState.listOpen
    }))
  }

render(){


  const{list} = this.props
  const{listOpen, headerTitle} = this.state

  return(
    <div className={this.state.cssClass + "-wrapper"}>
      <div className={this.state.cssClass + "-header"} onClick={() => this.toggleList()}>
          <div className ="text">
            <header>{this.state.id}</header>
            {headerTitle}
          </div>
          <img src={this.state.image} alt =""/>
      </div>
        {listOpen &&
          <div className={this.state.cssClass + "-list"}>
            {list.map((item) => (
              <div className={this.state.cssClass + "-list-item"} key={item.id} onClick={() => {this.state.dropDownHandler(item.title, this.state.id); this.toggleList()}}>
                  {item.title}
              </div>
            ))}
          </div>
        }
      </div>

      )
    }
}


export default onClickOutside(DropDown);
