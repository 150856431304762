import React from "react"

import "./selection_unit.css"
import gameInfo from "../GameAndPlayerData/GameData.js"
import ratingValues from "../GameAndPlayerData/ratingValues.js"
import Player from "./Player.js"
import axios from 'axios';
import logo from "./assets/logo.png"
import empty_img from "./assets/empty.svg"

import DropDown from "../DropDown/DropDown.js"
import LockList from "./RatingLists/LockList"
import SubmitButton from "./SubmitButton.js"

const API_PATH = 'https://lineupgod.com/api/football/generate_lineups.php';
//const API_PATH = 'http://localhost:80/lineupGod_php/api/football/generate_lineups.php';

class SelectionUnit extends React.Component {

    constructor(props,context){
      super(props,context);

      this.handleRatingChange = this.handleRatingChange.bind(this);
      this.handleSubmitClick = this.handleSubmitClick.bind(this);
      this.handleGameChange = this.handleGameChange.bind(this);
      this.dropDownListener = this.dropDownListener.bind(this);
      this.isLockIssue = this.isLockIssue.bind(this);

      var site = gameInfo[0].site
      var slate_criteria =  gameInfo[0].slates[0].criteria

      var slatePlayers = props.playerArray.filter( p => slate_criteria.includes(p["game_time"]))

         slatePlayers = slatePlayers.map(p => {
               return ({
                     "id" : p.id,
                     "name" : p.name,
                     "pos" : p.pos,
                     "num" : p.num,
                     "team" : p.team,
                     "opp" : p.opp,
                     "rating" : "None",
                     "pts" : p[site + "_pts"],
                     "sal" : p[site + "_sal"]
               })
           })



      let all_list = slatePlayers.sort((a,b) => b.pts - a.pts)
          all_list = all_list.map(item =>
                                     <Player key = {item.id}
                                             id = {item.id}
                                             name = {item.name}
                                             pos = {item.pos}
                                             num = {item.num}
                                             team = {item.team}
                                             opp = {item.opp}
                                             points = {item.pts}
                                             salary = {item.sal}
                                             ratingValue = {item.rating}
                                             parentHandleRatingChange = {this.handleRatingChange}
                                             />)

      this.state = {
        gameSite: gameInfo[0],
        gameSlate: gameInfo[0].slates[0].title,
        positionKey: "All",
        ratingKey: "All",
        playerArray: props.playerArray.sort((a,b) => b.pts - a.pts),
        playerComponents: all_list,
        lockList: [],
        lockIssue: false,
        lineupHandler: props.lineupHandler,
        isWaitingforLineups: false
      };
    }

    componentDidUpdate(prevProps, prevState){

      if(prevProps.positionKey !== this.props.positionKey){

        var position

        switch(this.props.positionKey){
          case "Quarterbacks":
            position = "QB"
            break;
          case "Running Backs":
            position = "RB"
            break;
          case "Wide Recievers":
            position = "WR"
            break;
          case "Tight Ends":
            position = "TE"
            break;
          case "Flex":
            position = "RB/WR/TE"
            break;
          case "Defense":
            position = "DST"
            break;
          default:
            position = "All"
        }

        this.setState({positionKey: position})
    }
  }

    dropDownListener(item, id){
      if(this.state.isWaitingforLineups){
        return
      }else if(id === 'site' || id === 'slate'){
        this.handleGameChange(item, id)
      }else if( id === 'rating'){
        this.setState({ratingKey: item})
      }

    }

    handleGameChange(item, id){

      var site
      var slate
      var slate_criteria

      if(id === 'site'){

        if(item === this.state.gameSite.title)
          return

          site = gameInfo.filter(game => item === game.title)
          site = site[0]
          slate = site.slates[0].title
          slate_criteria = site.slates[0].criteria
      }else if ( id === 'slate'){

        if(item === this.state.gameSlate)
          return

        site = this.state.gameSite
        slate = item
        slate_criteria = site.slates.find(s => s.title === slate).criteria
      }

      let slatePlayers = this.state.playerArray.filter(p => slate_criteria.includes(p["game_time"]))

      let newLockList = slatePlayers.filter(player => player.rating === "Lock")

      let newLockIssue = this.isLockIssue(newLockList)

      slatePlayers = slatePlayers.sort( (a,b) => b[site.site + "_pts"] - a[site.site + "_pts"])

      slatePlayers = slatePlayers.map(p =>{

                                  return( <Player key = {p.id + site.site}
                                          id = {p.id}
                                          name = {p.name}
                                          pos = {p.pos}
                                          num = {p.num}
                                          team = {p.team}
                                          opp = {p.opp}
                                          points = {p[site.site + "_pts"]}
                                          salary = {p[site.site + "_sal"]}
                                          ratingValue = {p.rating}
                                          parentHandleRatingChange = {this.handleRatingChange}
                                          />)
                                        })

     this.setState({
             gameSite: site,
             gameSlate: slate,
             playerComponents: slatePlayers,
             lockList: newLockList,
             lockIssue: newLockIssue
           })

    }

    handleRatingChange(value, id){

        this.setState( prevState => {
            let updatedPlayerArray = prevState.playerArray.map(player => {
              if (id === player.id){
                player.rating = value
              }
              return player})

            let newLockList = updatedPlayerArray.filter(player => player.rating === "Lock" && this.state.gameSite.slates.find(s => s.title === this.state.gameSlate).criteria.includes(player["game_time"]))

            let newLockIssue = this.isLockIssue(newLockList)

            let all_list = prevState.playerComponents.map(component => {
              if (id === component.props.id){
                return    <Player key = {component.props.id}
                                             id = {component.props.id}
                                             name = {component.props.name}
                                             pos = {component.props.pos}
                                             num = {component.props.num}
                                             team = {component.props.team}
                                             opp = {component.props.opp}
                                             points = {component.props.points}
                                             salary = {component.props.salary}
                                             ratingValue = {value}
                                             parentHandleRatingChange = {this.handleRatingChange}/>
                                           }
                else return component
                                         })
            return{
                 playerArray: updatedPlayerArray,
                 playerComponents: all_list,
                 lockList: newLockList,
                 lockIssue: newLockIssue
              }


        })

    }

    isLockIssue(newLockList){

    let newLockIssue = false;

    let qb_list = newLockList.filter(player => player.pos === "QB")
    let rb_list = newLockList.filter(player => player.pos === "RB")
    let wr_list = newLockList.filter(player => player.pos === "WR")
    let te_list = newLockList.filter(player => player.pos === "TE")
    let dst_list = newLockList.filter(player => player.pos === "DST")

    if( (qb_list.length > 1) ||
        (rb_list.length > 3) ||
        (wr_list.length > 4) ||
        (te_list.length > 2) ||
        (dst_list.length > 1) ||
        (rb_list.length + wr_list.length + te_list.length > 7)||
        (rb_list.length + wr_list.length > 6)||
        (rb_list.length + te_list.length > 4)||
        (wr_list.length + te_list.length > 5) )
        newLockIssue = true

    let maxCap = this.state.gameSite.maxCap

    var minReqCap = (qb_list.length >= 1 ? 0 : this.state.gameSite.minQB)
                  + (rb_list.length >= 2 ? 0 : (2 - rb_list.length)) * this.state.gameSite.minRB
                  + (wr_list.length >= 3 ? 0 : (3 - wr_list.length)) * this.state.gameSite.minWR
                  + (te_list.length >= 1 ? 0 : 1) * this.state.gameSite.minTE
                  + (dst_list.length >= 1 ? 0 : 1) * this.state.gameSite.minDST
                  + ((rb_list.length > 2 ||
                      wr_list.length > 3 ||
                      te_list.length > 1) ? 0 : 1) * this.state.gameSite.minFlex


    var lockCap = 0

    if (newLockList.length > 0)
      lockCap = newLockList.map(item => item[this.state.gameSite.site + "_sal"]).reduce((prev, next) => prev + next);

    if(lockCap + minReqCap > maxCap)
      newLockIssue = true

      return newLockIssue
    }

    handleSubmitClick(e){
          e.preventDefault();

          if(this.state.lockIssue){
            this.setState({
              ratingKey: "Lock",
              positionKey: "All"
            })
          }else if(this.state.isWaitingforLineups){
            console.log("Still waiting u fucker")
          }else{

            this.setState({isWaitingforLineups: true})

            var start = Date.now();

            var slate_criteria =  this.state.gameSite.slates.find(s => s.title === this.state.gameSlate).criteria

            var slatePlayers = this.state.playerArray.filter(p => slate_criteria.includes(p["game_time"]))

               slatePlayers = slatePlayers.map(p => {
                     return ({
                           "id" : p.id,
                           "name" : p.name,
                           "pos" : p.pos,
                           "num" : p.num,
                           "team" : p.team,
                           "opp" : p.opp,
                           "rating" : p.rating,
                           "pts" : p[this.state.gameSite.site + "_pts"],
                           "sal" : p[this.state.gameSite.site + "_sal"]
                     })
                 })

          console.log(slatePlayers)

            var stringifiedJSON = JSON.stringify({cap: this.state.gameSite.maxCap, players: slatePlayers})

                axios({
                method: 'post',
                url: `${API_PATH}`,
                data: stringifiedJSON,
                headers: {

                    'Content-Type': 'application/json;charset=UTF-8',
                    "Accept" : "application/json"
                  }
                })
                .then(result => {

                  this.setState({isWaitingforLineups: false})

                  console.log(result.data)

                  this.state.lineupHandler(result.data, slatePlayers, this.state.gameSite.title, this.state.gameSlate)

                  var millis = Date.now() - start;

                  console.log("seconds elapsed = " + Math.floor(millis/1000));

              })
              .catch();
            }



          };


    render(){

      var allShow = 'positionList' + ((this.state.ratingKey !== 'Lock') ? '--active' : "")
      var lockShow = 'positionList' + ((this.state.ratingKey !== 'Lock') ? "" : '--active')

      var playerList;

      switch(this.state.positionKey){

        case "QB":
          playerList = this.state.playerComponents.filter(p => p.props.pos.includes("QB") && (this.state.ratingKey === 'All' || p.props.ratingValue === this.state.ratingKey))
          break;
        case "RB":
          playerList = this.state.playerComponents.filter(p => p.props.pos.includes("RB") && (this.state.ratingKey === 'All' || p.props.ratingValue === this.state.ratingKey))
          break;
        case "WR":
          playerList = this.state.playerComponents.filter(p => p.props.pos.includes("WR") && (this.state.ratingKey === 'All' || p.props.ratingValue === this.state.ratingKey))
          break;
        case "TE":
          playerList = this.state.playerComponents.filter(p => p.props.pos.includes("TE") && (this.state.ratingKey === 'All' || p.props.ratingValue === this.state.ratingKey))
          break;
        case "DST":
          playerList = this.state.playerComponents.filter(p => p.props.pos.includes("DST") && (this.state.ratingKey === 'All' || p.props.ratingValue === this.state.ratingKey))
          break;
        case "RB/WR/TE":
          playerList = this.state.playerComponents.filter(p=> "RB/WR/TE".includes(p.props.pos) && (this.state.ratingKey === 'All' || p.props.ratingValue === this.state.ratingKey))
          break;
        default:
          playerList = this.state.playerComponents.filter(p => (this.state.ratingKey === 'All' || p.props.ratingValue === this.state.ratingKey))

      }

        return(
          <div className = "selectionUnit">
            <div className= "site-bar">
                        <DropDown id="site"
                                  cssClass={"siteList"}
                                  title={this.state.gameSite.title}
                                  list={gameInfo}
                                  dropDownHandler={this.dropDownListener}
                                  image = {empty_img}
                                  />
                        <DropDown id="slate"
                                  cssClass={"slateList"}
                                  title={this.state.gameSlate}
                                  list={this.state.gameSite.slates}
                                  dropDownHandler={this.dropDownListener}
                                  image = {empty_img}
                                  />
                        <DropDown id="rating"
                                  cssClass={"ratingList"}
                                  title={this.state.ratingKey}
                                  list={ratingValues}
                                  dropDownHandler={this.dropDownListener}
                                  image = {empty_img}
                                  />
            </div>

            <div className = {'playerListContainer' + (this.state.isWaitingforLineups ? "--hidden" : "")}>

              <div className = {allShow}>
                {playerList}
                <div className = "buffer"/>
              </div>
              <div className = {lockShow}>
                {<LockList key = {808}
                           players = {this.state.lockList}
                           positionKey = {this.state.positionKey}
                           gameSite = {this.state.gameSite}
                           parentHandleRatingChange = {this.handleRatingChange}
                           />}
                <div className = "buffer"/>
              </div>
            </div>

            <div className = {'waitSplash' + (this.state.isWaitingforLineups ? "" : "--hidden")}>
              <img src = {logo} alt ="Searching the Cosmos for Lineups"/>
            </div>

            <div className = "submitButton">
              <SubmitButton isWaitingforLineups = {this.state.isWaitingforLineups}
                            lockIssue = {this.state.lockIssue}
                            onClick = {this.handleSubmitClick}/>
            </div>
          </div>
        );
      }

}

export default SelectionUnit
