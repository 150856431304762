import React from 'react';
import LineupCard from "./LineupCard.js"
import DropDown from "../DropDown/DropDown.js"
import "./LineupView.css"
import empty_img from "../SelectionUnit/assets/empty.svg"
import uuid from "uuid"

class LineupView extends React.Component{


constructor(props,context){
  super(props,context);

  var lineup_data = props.data.lineupData
  var playerArray = props.data.playerData

  var lineups;

  if(lineup_data.length > 0 ){
    lineups = lineup_data.map( lineup =>

    <LineupCard key = {uuid.v4()}
                site ={props.data.site}
                slate = {props.data.slate}
                qb = {playerArray.find(player => player.id === lineup.qb)}
                rb1 = {playerArray.find(player => player.id === lineup.rb1)}
                rb2 = {playerArray.find(player => player.id === lineup.rb2)}
                wr1 = {playerArray.find(player => player.id === lineup.wr1)}
                wr2 = {playerArray.find(player => player.id === lineup.wr2)}
                wr3 = {playerArray.find(player => player.id === lineup.wr3)}
                te = {playerArray.find(player => player.id === lineup.te)}
                flex = {playerArray.find(player => player.id === lineup.flex)}
                dst = {playerArray.find(player => player.id === lineup.dst)}
                />
    )
  }

  this.state = {
    site: props.data.site,
    slate: props.data.slate,
    lineupComponents: lineups,
    favoriteLineups: []
  }
}

  componentDidUpdate(prevProps, prevState){

        if(prevProps.data.lineupData !== this.props.data.lineupData){

          var lineup_data = this.props.data.lineupData
          var playerArray = this.props.data.playerData

          var lineups;

          if(lineup_data.length > 0 ){

            lineups = lineup_data.map( lineup =>

            <LineupCard key = {lineup.key}
                        site ={this.props.data.site}
                        slate = {this.props.data.slate}
                        qb = {playerArray.find(player => player.id === lineup.qb)}
                        rb1 = {playerArray.find(player => player.id === lineup.rb1)}
                        rb2 = {playerArray.find(player => player.id === lineup.rb2)}
                        wr1 = {playerArray.find(player => player.id === lineup.wr1)}
                        wr2 = {playerArray.find(player => player.id === lineup.wr2)}
                        wr3 = {playerArray.find(player => player.id === lineup.wr3)}
                        te = {playerArray.find(player => player.id === lineup.te)}
                        flex = {playerArray.find(player => player.id === lineup.flex)}
                        dst = {playerArray.find(player => player.id === lineup.dst)}
                        />
            )
          }

          this.setState({
            site: this.props.data.site,
            slate: this.props.data.slate,
            lineupComponents: lineups
          })
        }

  }

  render() {

    return (
          <div>
            <div className= "site-bar">
                        <DropDown id="site and slate"
                                  cssClass={"lineupSiteSlateList"}
                                  title={this.state.site + " " + this.state.slate}
                                  list={[]}
                                  dropDownHandler={this.dropDownListener}
                                  image = {empty_img}
                                  />
            </div>
            <div className = "lineupView">
              {this.state.lineupComponents}
            </div>
          </div>
    );
  }

}
export default LineupView;
