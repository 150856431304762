import React from "react"
import './LineupCard.css';
import unbookmark from "../SelectionUnit/assets/favorite-btn.svg"
import bookmark from "../SelectionUnit/assets/favorite-active-btn.svg"

function BookmarkButton (props){

    if(props.isBookmarked){
      return(<div className = "bookmarkBtn"
                  onClick = {() => props.handleBookmarkChange(!props.isBookmarked)}>
                  <img src = {bookmark} alt ="Bookmarked"/>
              </div>)
    }

   return(<div className = "bookmarkBtn"
                        onClick = {() => props.handleBookmarkChange(!props.isBookmarked)}>
                        <img src = {unbookmark} alt ="No Bookmark"/>
                     </div>)

}


export default BookmarkButton
