import React from "react"
import "./Ratingbutton.css"
import lock_off from "./assets/lock-off-btn.svg"
import lock_default from "./assets/lock-btn.svg"
import lock_selected from "./assets/lock-active-btn.svg"
import like_off from "./assets/like-off-btn.svg"
import like_default from "./assets/like-btn.svg"
import like_selected from "./assets/like-active-btn.svg"
import avoid_off from "./assets/avoid-off-btn.svg"
import avoid_default from "./assets/avoid-btn.svg"
import avoid_selected from "./assets/avoid-active-btn.svg"
import forbid_off from "./assets/forbid-off-btn.svg"
import forbid_default from "./assets/forbid-btn.svg"
import forbid_selected from "./assets/forbid-active-btn.svg"


function RatingButtons (props){

      var lock_img;
      var like_img;
      var avoid_img;
      var forbid_img;

      switch(props.ratingValue){
          case "Lock":
                   lock_img = lock_selected
                   like_img = like_off
                   avoid_img = avoid_off
                   forbid_img = forbid_off
            break;
          case "Like":
                   lock_img = lock_off
                   like_img = like_selected
                   avoid_img = avoid_off
                   forbid_img = forbid_off
            break;
          case "Avoid":
                   lock_img = lock_off
                   like_img = like_off
                   avoid_img = avoid_selected
                   forbid_img = forbid_off
            break;
          case "Forbid":
                   lock_img = lock_off
                   like_img = like_off
                   avoid_img = avoid_off
                   forbid_img = forbid_selected
            break;
          default:
                   lock_img = lock_default
                   like_img = like_default
                   avoid_img = avoid_default
                   forbid_img = forbid_default 

      }

  return (
            <div className = "buttonGroup">
                <div className="row">
                  <div
                    value={2}
                    className = "column"
                    onClick = {() => props.handleRatingChange("Like")}>
                    <img src={like_img} alt="Like" title ="Like"/>
                  </div>
                  <div
                    value={3}
                    className = "column"
                    onClick = {() => props.handleRatingChange("Avoid")}>
                    <img src={avoid_img} alt="Avoid" title ="Avoid"/>
                  </div>
                </div>
                <div className="row">
                  <div
                    value={1}
                    className = "column"
                    onClick = {() => props.handleRatingChange("Lock")}>
                    <img src={lock_img} alt="Lock" title ="Lock"/>
                  </div>
                  <div
                    value={4}
                    className = "column"
                    onClick = {() => props.handleRatingChange("Forbid")}>
                    <img src={forbid_img} alt="Forbid" title ="Forbid"/>
                  </div>
                </div>
            </div>
        )
}

export default RatingButtons
