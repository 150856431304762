import React from "react";

import './App.css';
import playerInfo from "./GameAndPlayerData/PlayerData.js"
import mainDropDownList from './DropDown/mainDropDownListValues.js'
import logo from "./SelectionUnit/assets/logo.png"
import dd_img from "./SelectionUnit/assets/nav-open-btn.svg"

import SelectionUnit from "./SelectionUnit/SelectionUnit.js";
import LineupView from "./LineupView/LineupView.js"
import DropDown from "./DropDown/DropDown.js"

class App extends React.Component{

  constructor(props){

    super(props)
    this.dropDownListener = this.dropDownListener.bind(this);
    this.lineupListener = this.lineupListener.bind(this);

    this.state = {
      mainDropDown: "All Players",
      lineups: {lineupData: [], playerData: [], site: "", slate:""}
    }

  }


  dropDownListener(item, id){

      if(id ==="Position"){
      this.setState({
        mainDropDown: item
        })
      }
  }

  lineupListener(lineups, playerArray, site, slate){

    this.setState({
      mainDropDown: "Lineups",
      lineups: {lineupData:lineups, playerData:playerArray, site:site, slate:slate}
    })

  }



  render(){

    return (
      <div className="page-container">
          <div className="App-header">
            <div className="top-bar">
                  <img src= {logo} alt ="LineupGod"/>
                  <DropDown id ="Position"
                            cssClass={"positionList"}
                            title={this.state.mainDropDown}
                            list={mainDropDownList.filter(item => item.title !== this.state.mainDropDown)}
                            dropDownHandler={this.dropDownListener}
                            image = {dd_img}
                            />
            </div>
          </div>
          <div className ="viewHeader"/>
          <div className = "background"/>
            <div className= {"viewContainer" + ((this.state.mainDropDown === "Lineups") ? "-hidden" : '')}>
                      <SelectionUnit playerArray = {playerInfo}
                                     positionKey = {this.state.mainDropDown}
                                     lineupHandler ={this.lineupListener}/>
            </div>
            <div className={"viewContainer" + ((this.state.mainDropDown === "Lineups") ? "" : '-hidden')}>
                      <LineupView data = {this.state.lineups}/>
            </div>

      </div>

      );
  }
}

export default App;
