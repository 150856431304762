const players = [
{
id: 1000,
name: 'AJ Brown',
team: 'TEN',
pos: 'WR',
num: '#11',
opp: 'ATL 12th',
game_time: 'SE',
dk_sal: 3700,
dk_pts: 7.8,
fd_sal: 4700,
fd_pts: 6.3,
y_sal: 14,
y_pts: 6.3,
rating: 'None',
},
{
id: 1001,
name: 'Aaron Jones',
team: 'GB',
pos: 'RB',
num: '#33',
opp: 'PHI 5th',
game_time: 'T',
dk_sal: 6300,
dk_pts: 14.3,
fd_sal: 6900,
fd_pts: 13.1,
y_sal: 20,
y_pts: 13.1,
rating: 'None',
},
{
id: 1002,
name: 'Aaron Rodgers',
team: 'GB',
pos: 'QB',
num: '#12',
opp: 'PHI 23rd',
game_time: 'T',
dk_sal: 6000,
dk_pts: 19.6,
fd_sal: 7700,
fd_pts: 19.6,
y_sal: 35,
y_pts: 19.6,
rating: 'None',
},
{
id: 1003,
name: 'Adam Humphries',
team: 'TEN',
pos: 'WR',
num: '#10',
opp: 'ATL 12th',
game_time: 'SE',
dk_sal: 4000,
dk_pts: 7.6,
fd_sal: 5200,
fd_pts: 6.1,
y_sal: 10,
y_pts: 6.1,
rating: 'None',
},
{
id: 1004,
name: 'Adam Shaheen',
team: 'CHI',
pos: 'TE',
num: '#87',
opp: 'MIN 18th',
game_time: 'SL',
dk_sal: 3100,
dk_pts: 3.2,
fd_sal: 4500,
fd_pts: 2.6,
y_sal: 10,
y_pts: 2.6,
rating: 'None',
},
{
id: 1005,
name: 'Adam Thielen',
team: 'MIN',
pos: 'WR',
num: '#19',
opp: 'CHI 15th',
game_time: 'SL',
dk_sal: 6600,
dk_pts: 14.3,
fd_sal: 7000,
fd_pts: 11.7,
y_sal: 22,
y_pts: 11.7,
rating: 'None',
},
{
id: 1006,
name: 'Adrian Peterson',
team: 'WAS',
pos: 'RB',
num: '#26',
opp: 'NYG 25th',
game_time: 'SE',
dk_sal: 4300,
dk_pts: 10.5,
fd_sal: 5500,
fd_pts: 9.8,
y_sal: 15,
y_pts: 9.8,
rating: 'None',
},
{
id: 1008,
name: 'AJ McCarron',
team: 'HOU',
pos: 'QB',
num: '',
opp: 'CAR 8th',
game_time: 'SE',
dk_sal: 4100,
dk_pts: 0.2,
fd_sal: 6000,
fd_pts: 0.2,
y_sal: 20,
y_pts: 0.2,
rating: 'None',
},
{
id: 1010,
name: 'Albert Wilson',
team: 'MIA',
pos: 'WR',
num: '#15',
opp: 'LAC 16th',
game_time: 'SE',
dk_sal: 4000,
dk_pts: 4.3,
fd_sal: 4600,
fd_pts: 3.5,
y_sal: 10,
y_pts: 3.5,
rating: 'None',
},
{
id: 1012,
name: 'Alex Armah',
team: 'CAR',
pos: 'RB',
num: '',
opp: 'HOU 10th',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 1.1,
fd_sal: 4500,
fd_pts: 1,
y_sal: 10,
y_pts: 1,
rating: 'None',
},
{
id: 1013,
name: 'Alex Ellis',
team: 'PHI',
pos: 'TE',
num: '',
opp: 'GB 1st',
game_time: 'T',
dk_sal: 2500,
dk_pts: 0.1,
fd_sal: 4000,
fd_pts: 0.1,
y_sal: 10,
y_pts: 0.1,
rating: 'None',
},
{
id: 1014,
name: 'Alex Erickson',
team: 'CIN',
pos: 'WR',
num: '#12',
opp: 'PIT 26th',
game_time: 'M',
dk_sal: 3200,
dk_pts: 2.6,
fd_sal: 4500,
fd_pts: 2.2,
y_sal: 10,
y_pts: 2.2,
rating: 'None',
},
{
id: 1017,
name: 'Alexander Mattison',
team: 'MIN',
pos: 'RB',
num: '#25',
opp: 'CHI 4th',
game_time: 'SL',
dk_sal: 3900,
dk_pts: 4.4,
fd_sal: 4900,
fd_pts: 4.2,
y_sal: 13,
y_pts: 4.2,
rating: 'None',
},
{
id: 1021,
name: 'Allen Hurns',
team: 'MIA',
pos: 'WR',
num: '#17',
opp: 'LAC 16th',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 3.4,
fd_sal: 4500,
fd_pts: 2.7,
y_sal: 10,
y_pts: 2.7,
rating: 'None',
},
{
id: 1022,
name: 'Allen Lazard',
team: 'GB',
pos: 'WR',
num: '',
opp: 'PHI 29th',
game_time: 'T',
dk_sal: 3000,
dk_pts: 0.7,
fd_sal: 4500,
fd_pts: 0.6,
y_sal: 10,
y_pts: 0.6,
rating: 'None',
},
{
id: 1023,
name: 'Allen Robinson',
team: 'CHI',
pos: 'WR',
num: '#12',
opp: 'MIN 20th',
game_time: 'SL',
dk_sal: 5600,
dk_pts: 14.3,
fd_sal: 7000,
fd_pts: 11.7,
y_sal: 19,
y_pts: 11.7,
rating: 'None',
},
{
id: 1024,
name: 'Alshon Jeffery',
team: 'PHI',
pos: 'WR',
num: '#17',
opp: 'GB 3rd',
game_time: 'T',
dk_sal: 6100,
dk_pts: 11.6,
fd_sal: 6700,
fd_pts: 9.6,
y_sal: 17,
y_pts: 9.6,
rating: 'None',
},
{
id: 1025,
name: 'Alvin Kamara',
team: 'NO',
pos: 'RB',
num: '#41',
opp: 'DAL 12th',
game_time: 'SN',
dk_sal: 8500,
dk_pts: 21.4,
fd_sal: 8000,
fd_pts: 18.7,
y_sal: 30,
y_pts: 18.7,
rating: 'None',
},
{
id: 1026,
name: 'Amari Cooper',
team: 'DAL',
pos: 'WR',
num: '#19',
opp: 'NO 30th',
game_time: 'SN',
dk_sal: 7300,
dk_pts: 17.3,
fd_sal: 7900,
fd_pts: 14.6,
y_sal: 26,
y_pts: 14.6,
rating: 'None',
},
{
id: 1027,
name: 'Ameer Abdullah',
team: 'MIN',
pos: 'RB',
num: '#31',
opp: 'CHI 4th',
game_time: 'SL',
dk_sal: 3000,
dk_pts: 0.8,
fd_sal: 4500,
fd_pts: 0.7,
y_sal: 10,
y_pts: 0.7,
rating: 'None',
},
{
id: 1029,
name: 'Andre Roberts',
team: 'BUF',
pos: 'WR',
num: '#8',
opp: 'NE 1st',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 0.7,
fd_sal: 4500,
fd_pts: 0.5,
y_sal: 10,
y_pts: 0.5,
rating: 'None',
},
{
id: 1030,
name: 'Andrew Beck',
team: 'DEN',
pos: 'TE',
num: '',
opp: 'JAX 17th',
game_time: 'SL',
dk_sal: 2500,
dk_pts: 1.1,
fd_sal: 4000,
fd_pts: 1,
y_sal: 10,
y_pts: 1,
rating: 'None',
},
{
id: 1031,
name: 'Andy Dalton',
team: 'CIN',
pos: 'QB',
num: '#14',
opp: 'PIT 25th',
game_time: 'M',
dk_sal: 5200,
dk_pts: 17.3,
fd_sal: 7100,
fd_pts: 17.3,
y_sal: 30,
y_pts: 17.3,
rating: 'None',
},
{
id: 1032,
name: 'Andy Isabella',
team: 'ARI',
pos: 'WR',
num: '',
opp: 'SEA 9th',
game_time: 'SL',
dk_sal: 3100,
dk_pts: 2.7,
fd_sal: 4500,
fd_pts: 2.2,
y_sal: 10,
y_pts: 2.2,
rating: 'None',
},
{
id: 1033,
name: 'Andy Janovich',
team: 'DEN',
pos: 'RB',
num: '',
opp: 'JAX 13th',
game_time: 'SL',
dk_sal: 3000,
dk_pts: 0.8,
fd_sal: 4500,
fd_pts: 0.6,
y_sal: 10,
y_pts: 0.6,
rating: 'None',
},
{
id: 1034,
name: 'Anthony Firkser',
team: 'TEN',
pos: 'TE',
num: '',
opp: 'ATL 16th',
game_time: 'SE',
dk_sal: 2500,
dk_pts: 0.5,
fd_sal: 4000,
fd_pts: 0.4,
y_sal: 10,
y_pts: 0.4,
rating: 'None',
},
{
id: 1035,
name: 'Anthony Miller',
team: 'CHI',
pos: 'WR',
num: '#17',
opp: 'MIN 20th',
game_time: 'SL',
dk_sal: 3700,
dk_pts: 5.5,
fd_sal: 5200,
fd_pts: 4.6,
y_sal: 10,
y_pts: 4.6,
rating: 'None',
},
{
id: 1036,
name: 'Anthony Sherman',
team: 'KC',
pos: 'RB',
num: '#42',
opp: 'DET 26th',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 1,
fd_sal: 4500,
fd_pts: 0.8,
y_sal: 10,
y_pts: 0.8,
rating: 'None',
},
{
id: 1039,
name: 'Antony Auclair',
team: 'TB',
pos: 'TE',
num: '',
opp: 'LAR 9th',
game_time: 'SL',
dk_sal: 2500,
dk_pts: 0.5,
fd_sal: 4000,
fd_pts: 0.4,
y_sal: 10,
y_pts: 0.4,
rating: 'None',
},
{
id: 1040,
name: 'Cardinals',
team: 'ARI',
pos: 'DST',
num: '',
opp: 'SEA 9th',
game_time: 'SL',
dk_sal: 2400,
dk_pts: 7.1,
fd_sal: 3200,
fd_pts: 7.1,
y_sal: 13,
y_pts: 7.1,
rating: 'None',
},
{
id: 1043,
name: 'Falcons',
team: 'ATL',
pos: 'DST',
num: '',
opp: 'TEN 27th',
game_time: 'SE',
dk_sal: 2900,
dk_pts: 7.5,
fd_sal: 3900,
fd_pts: 7.5,
y_sal: 13,
y_pts: 7.5,
rating: 'None',
},
{
id: 1044,
name: 'Auden Tate',
team: 'CIN',
pos: 'WR',
num: '#19',
opp: 'PIT 26th',
game_time: 'M',
dk_sal: 3200,
dk_pts: 6.8,
fd_sal: 5000,
fd_pts: 5.5,
y_sal: 10,
y_pts: 5.5,
rating: 'None',
},
{
id: 1045,
name: 'Austin Carr',
team: 'NO',
pos: 'WR',
num: '',
opp: 'DAL 6th',
game_time: 'SN',
dk_sal: 3000,
dk_pts: 0.8,
fd_sal: 4500,
fd_pts: 0.7,
y_sal: 10,
y_pts: 0.7,
rating: 'None',
},
{
id: 1046,
name: 'Austin Ekeler',
team: 'LAC',
pos: 'RB',
num: '#30',
opp: 'MIA 32nd',
game_time: 'SE',
dk_sal: 8000,
dk_pts: 20.7,
fd_sal: 8100,
fd_pts: 18.4,
y_sal: 31,
y_pts: 18.4,
rating: 'None',
},
{
id: 1047,
name: 'Austin Hooper',
team: 'ATL',
pos: 'TE',
num: '#81',
opp: 'TEN 27th',
game_time: 'SE',
dk_sal: 4300,
dk_pts: 10.6,
fd_sal: 6400,
fd_pts: 8.3,
y_sal: 17,
y_pts: 8.3,
rating: 'None',
},
{
id: 1048,
name: 'Baker Mayfield',
team: 'CLE',
pos: 'QB',
num: '#6',
opp: 'BAL 15th',
game_time: 'SE',
dk_sal: 5800,
dk_pts: 17.6,
fd_sal: 7500,
fd_pts: 17.6,
y_sal: 32,
y_pts: 17.6,
rating: 'None',
},
{
id: 1049,
name: 'Ravens',
team: 'BAL',
pos: 'DST',
num: '',
opp: 'CLE 24th',
game_time: 'SE',
dk_sal: 3200,
dk_pts: 8,
fd_sal: 4200,
fd_pts: 8,
y_sal: 20,
y_pts: 8,
rating: 'None',
},
{
id: 1050,
name: 'Ben Braunecker',
team: 'CHI',
pos: 'TE',
num: '',
opp: 'MIN 18th',
game_time: 'SL',
dk_sal: 2900,
dk_pts: 1.3,
fd_sal: 4000,
fd_pts: 1,
y_sal: 10,
y_pts: 1,
rating: 'None',
},
{
id: 1053,
name: 'Bennie Fowler',
team: 'NYG',
pos: 'WR',
num: '#18',
opp: 'WAS 31st',
game_time: 'SE',
dk_sal: 3500,
dk_pts: 4.9,
fd_sal: 4700,
fd_pts: 3.9,
y_sal: 10,
y_pts: 3.9,
rating: 'None',
},
{
id: 1054,
name: 'Benny Snell Jr',
team: 'PIT',
pos: 'RB',
num: '',
opp: 'CIN 31st',
game_time: 'M',
dk_sal: 3300,
dk_pts: 0.6,
fd_sal: 4500,
fd_pts: 0.6,
y_sal: 10,
y_pts: 0.6,
rating: 'None',
},
{
id: 1056,
name: 'Blake Bell',
team: 'KC',
pos: 'TE',
num: '',
opp: 'DET 6th',
game_time: 'SE',
dk_sal: 2500,
dk_pts: 2.1,
fd_sal: 4000,
fd_pts: 1.7,
y_sal: 10,
y_pts: 1.7,
rating: 'None',
},
{
id: 1057,
name: 'Blake Bortles',
team: 'LAR',
pos: 'QB',
num: '#5',
opp: 'TB 20th',
game_time: 'SL',
dk_sal: 4100,
dk_pts: 0.7,
fd_sal: 6000,
fd_pts: 0.7,
y_sal: 20,
y_pts: 0.7,
rating: 'None',
},
{
id: 1058,
name: 'Blake Jarwin',
team: 'DAL',
pos: 'TE',
num: '#89',
opp: 'NO 12th',
game_time: 'SN',
dk_sal: 2800,
dk_pts: 5.2,
fd_sal: 4500,
fd_pts: 4.3,
y_sal: 10,
y_pts: 4.3,
rating: 'None',
},
{
id: 1060,
name: 'Brandin Cooks',
team: 'LAR',
pos: 'WR',
num: '#12',
opp: 'TB 12th',
game_time: 'SL',
dk_sal: 6200,
dk_pts: 16,
fd_sal: 7300,
fd_pts: 13.4,
y_sal: 19,
y_pts: 13.4,
rating: 'None',
},
{
id: 1062,
name: 'Brandon Bolden',
team: 'NE',
pos: 'RB',
num: '',
opp: 'BUF 18th',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 1.3,
fd_sal: 4500,
fd_pts: 1.2,
y_sal: 10,
y_pts: 1.2,
rating: 'None',
},
{
id: 1065,
name: 'Brandon Zylstra',
team: 'CAR',
pos: 'WR',
num: '',
opp: 'HOU 24th',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 0.1,
fd_sal: 4500,
fd_pts: 0.1,
y_sal: 10,
y_pts: 0.1,
rating: 'None',
},
{
id: 1066,
name: 'Breshad Perriman',
team: 'TB',
pos: 'WR',
num: '#19',
opp: 'LAR 2nd',
game_time: 'SL',
dk_sal: 3200,
dk_pts: 5.9,
fd_sal: 4600,
fd_pts: 5,
y_sal: 10,
y_pts: 5,
rating: 'None',
},
{
id: 1067,
name: 'Brett Hundley',
team: 'ARI',
pos: 'QB',
num: '',
opp: 'SEA 14th',
game_time: 'SL',
dk_sal: 4100,
dk_pts: 0.5,
fd_sal: 6000,
fd_pts: 0.5,
y_sal: 20,
y_pts: 0.5,
rating: 'None',
},
{
id: 1069,
name: 'Brian Hill',
team: 'ATL',
pos: 'RB',
num: '',
opp: 'TEN 8th',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 1.9,
fd_sal: 4500,
fd_pts: 1.5,
y_sal: 10,
y_pts: 1.5,
rating: 'None',
},
{
id: 1070,
name: 'Brian Hoyer',
team: 'IND',
pos: 'QB',
num: '',
opp: 'OAK 24th',
game_time: 'SE',
dk_sal: 4100,
dk_pts: 0.6,
fd_sal: 6000,
fd_pts: 0.6,
y_sal: 20,
y_pts: 0.6,
rating: 'None',
},
{
id: 1072,
name: 'Bills',
team: 'BUF',
pos: 'DST',
num: '',
opp: 'NE 5th',
game_time: 'SE',
dk_sal: 2600,
dk_pts: 5.5,
fd_sal: 3400,
fd_pts: 5.5,
y_sal: 15,
y_pts: 5.5,
rating: 'None',
},
{
id: 1073,
name: 'Byron Pringle',
team: 'KC',
pos: 'WR',
num: '',
opp: 'DET 22nd',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 1.6,
fd_sal: 4500,
fd_pts: 1.2,
y_sal: 10,
y_pts: 1.2,
rating: 'None',
},
{
id: 1076,
name: 'Calvin Ridley',
team: 'ATL',
pos: 'WR',
num: '#18',
opp: 'TEN 10th',
game_time: 'SE',
dk_sal: 5000,
dk_pts: 12,
fd_sal: 5800,
fd_pts: 9.9,
y_sal: 20,
y_pts: 9.9,
rating: 'None',
},
{
id: 1079,
name: 'Cameron Brate',
team: 'TB',
pos: 'TE',
num: '#84',
opp: 'LAR 9th',
game_time: 'SL',
dk_sal: 2800,
dk_pts: 3.6,
fd_sal: 4500,
fd_pts: 2.8,
y_sal: 10,
y_pts: 2.8,
rating: 'None',
},
{
id: 1080,
name: 'Carlos Hyde',
team: 'HOU',
pos: 'RB',
num: '#34',
opp: 'CAR 22nd',
game_time: 'SE',
dk_sal: 4300,
dk_pts: 9.6,
fd_sal: 5900,
fd_pts: 9.1,
y_sal: 16,
y_pts: 9.1,
rating: 'None',
},
{
id: 1081,
name: 'Panthers',
team: 'CAR',
pos: 'DST',
num: '',
opp: 'HOU 17th',
game_time: 'SE',
dk_sal: 2300,
dk_pts: 7.5,
fd_sal: 3500,
fd_pts: 7.5,
y_sal: 13,
y_pts: 7.5,
rating: 'None',
},
{
id: 1082,
name: 'Carson Wentz',
team: 'PHI',
pos: 'QB',
num: '#11',
opp: 'GB 2nd',
game_time: 'T',
dk_sal: 5600,
dk_pts: 18.3,
fd_sal: 7700,
fd_pts: 18.3,
y_sal: 30,
y_pts: 18.3,
rating: 'None',
},
{
id: 1083,
name: 'Case Keenum',
team: 'WAS',
pos: 'QB',
num: '#8',
opp: 'NYG 29th',
game_time: 'SE',
dk_sal: 4900,
dk_pts: 17.6,
fd_sal: 7200,
fd_pts: 17.6,
y_sal: 28,
y_pts: 17.6,
rating: 'None',
},
{
id: 1086,
name: 'Chad Beebe',
team: 'MIN',
pos: 'WR',
num: '#12',
opp: 'CHI 15th',
game_time: 'SL',
dk_sal: 3300,
dk_pts: 0.9,
fd_sal: 4800,
fd_pts: 0.7,
y_sal: 10,
y_pts: 0.7,
rating: 'None',
},
{
id: 1090,
name: 'Charles Clay',
team: 'ARI',
pos: 'TE',
num: '#85',
opp: 'SEA 24th',
game_time: 'SL',
dk_sal: 2600,
dk_pts: 3.1,
fd_sal: 4200,
fd_pts: 2.4,
y_sal: 10,
y_pts: 2.4,
rating: 'None',
},
{
id: 1093,
name: 'Chase Daniel',
team: 'CHI',
pos: 'QB',
num: '',
opp: 'MIN 12th',
game_time: 'SL',
dk_sal: 4400,
dk_pts: 0.6,
fd_sal: 6000,
fd_pts: 0.6,
y_sal: 20,
y_pts: 0.6,
rating: 'None',
},
{
id: 1094,
name: 'Chase Edmonds',
team: 'ARI',
pos: 'RB',
num: '#29',
opp: 'SEA 17th',
game_time: 'SL',
dk_sal: 3600,
dk_pts: 3.2,
fd_sal: 4700,
fd_pts: 2.8,
y_sal: 10,
y_pts: 2.8,
rating: 'None',
},
{
id: 1096,
name: 'Chester Rogers',
team: 'IND',
pos: 'WR',
num: '#80',
opp: 'OAK 27th',
game_time: 'SE',
dk_sal: 3100,
dk_pts: 3.2,
fd_sal: 4700,
fd_pts: 2.6,
y_sal: 10,
y_pts: 2.6,
rating: 'None',
},
{
id: 1097,
name: 'Bears',
team: 'CHI',
pos: 'DST',
num: '',
opp: 'MIN 15th',
game_time: 'SL',
dk_sal: 3400,
dk_pts: 8,
fd_sal: 4700,
fd_pts: 8,
y_sal: 15,
y_pts: 8,
rating: 'None',
},
{
id: 1098,
name: 'Chris Carson',
team: 'SEA',
pos: 'RB',
num: '#32',
opp: 'ARI 14th',
game_time: 'SL',
dk_sal: 5700,
dk_pts: 16,
fd_sal: 7000,
fd_pts: 14.4,
y_sal: 22,
y_pts: 14.4,
rating: 'None',
},
{
id: 1099,
name: 'Chris Conley',
team: 'JAX',
pos: 'WR',
num: '#18',
opp: 'DEN 5th',
game_time: 'SL',
dk_sal: 4000,
dk_pts: 7.9,
fd_sal: 5100,
fd_pts: 6.4,
y_sal: 13,
y_pts: 6.4,
rating: 'None',
},
{
id: 1101,
name: 'Chris Godwin',
team: 'TB',
pos: 'WR',
num: '#12',
opp: 'LAR 2nd',
game_time: 'SL',
dk_sal: 6000,
dk_pts: 16,
fd_sal: 7100,
fd_pts: 13.3,
y_sal: 19,
y_pts: 13.3,
rating: 'None',
},
{
id: 1102,
name: 'Chris Hogan',
team: 'CAR',
pos: 'WR',
num: '#15',
opp: 'HOU 24th',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 3.7,
fd_sal: 4500,
fd_pts: 3,
y_sal: 10,
y_pts: 3,
rating: 'None',
},
{
id: 1103,
name: 'Chris Manhertz',
team: 'CAR',
pos: 'TE',
num: '',
opp: 'HOU 5th',
game_time: 'SE',
dk_sal: 2500,
dk_pts: 0.9,
fd_sal: 4000,
fd_pts: 0.8,
y_sal: 10,
y_pts: 0.8,
rating: 'None',
},
{
id: 1104,
name: 'Chris Moore',
team: 'BAL',
pos: 'WR',
num: '#10',
opp: 'CLE 11th',
game_time: 'SE',
dk_sal: 3400,
dk_pts: 2.2,
fd_sal: 4700,
fd_pts: 1.8,
y_sal: 10,
y_pts: 1.8,
rating: 'None',
},
{
id: 1106,
name: 'Chris Thompson',
team: 'WAS',
pos: 'RB',
num: '#25',
opp: 'NYG 25th',
game_time: 'SE',
dk_sal: 4500,
dk_pts: 12.9,
fd_sal: 5300,
fd_pts: 10.5,
y_sal: 13,
y_pts: 10.5,
rating: 'None',
},
{
id: 1108,
name: 'Christian Kirk',
team: 'ARI',
pos: 'WR',
num: '#13',
opp: 'SEA 9th',
game_time: 'SL',
dk_sal: 5100,
dk_pts: 14.1,
fd_sal: 5900,
fd_pts: 11.4,
y_sal: 19,
y_pts: 11.4,
rating: 'None',
},
{
id: 1109,
name: 'Christian McCaffrey',
team: 'CAR',
pos: 'RB',
num: '#22',
opp: 'HOU 10th',
game_time: 'SE',
dk_sal: 8800,
dk_pts: 23.2,
fd_sal: 9000,
fd_pts: 20.2,
y_sal: 38,
y_pts: 20.2,
rating: 'None',
},
{
id: 1112,
name: 'Bengals',
team: 'CIN',
pos: 'DST',
num: '',
opp: 'PIT 30th',
game_time: 'M',
dk_sal: 2600,
dk_pts: 6.1,
fd_sal: 3900,
fd_pts: 6.1,
y_sal: 11,
y_pts: 6.1,
rating: 'None',
},
{
id: 1114,
name: 'CJ Ham',
team: 'MIN',
pos: 'RB',
num: '',
opp: 'CHI 4th',
game_time: 'SL',
dk_sal: 3000,
dk_pts: 1.2,
fd_sal: 4500,
fd_pts: 0.9,
y_sal: 10,
y_pts: 0.9,
rating: 'None',
},
{
id: 1115,
name: 'CJ Prosise',
team: 'SEA',
pos: 'RB',
num: '#22',
opp: 'ARI 14th',
game_time: 'SL',
dk_sal: 4000,
dk_pts: 4.3,
fd_sal: 4800,
fd_pts: 3.4,
y_sal: 10,
y_pts: 3.4,
rating: 'None',
},
{
id: 1116,
name: 'CJ Uzomah',
team: 'CIN',
pos: 'TE',
num: '#87',
opp: 'PIT 22nd',
game_time: 'M',
dk_sal: 2600,
dk_pts: 5.3,
fd_sal: 4000,
fd_pts: 4.2,
y_sal: 12,
y_pts: 4.2,
rating: 'None',
},
{
id: 1118,
name: 'Browns',
team: 'CLE',
pos: 'DST',
num: '',
opp: 'BAL 1st',
game_time: 'SE',
dk_sal: 2500,
dk_pts: 6,
fd_sal: 3500,
fd_pts: 6,
y_sal: 13,
y_pts: 6,
rating: 'None',
},
{
id: 1119,
name: 'Cody Core',
team: 'NYG',
pos: 'WR',
num: '#16',
opp: 'WAS 31st',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 1.2,
fd_sal: 4500,
fd_pts: 0.9,
y_sal: 10,
y_pts: 0.9,
rating: 'None',
},
{
id: 1121,
name: 'Cody Latimer',
team: 'NYG',
pos: 'WR',
num: '#12',
opp: 'WAS 31st',
game_time: 'SE',
dk_sal: 3600,
dk_pts: 7.4,
fd_sal: 5000,
fd_pts: 6.1,
y_sal: 13,
y_pts: 6.1,
rating: 'None',
},
{
id: 1123,
name: 'Cole Beasley',
team: 'BUF',
pos: 'WR',
num: '#10',
opp: 'NE 1st',
game_time: 'SE',
dk_sal: 4700,
dk_pts: 9.3,
fd_sal: 5200,
fd_pts: 7.4,
y_sal: 10,
y_pts: 7.4,
rating: 'None',
},
{
id: 1126,
name: 'Cooper Kupp',
team: 'LAR',
pos: 'WR',
num: '#18',
opp: 'TB 12th',
game_time: 'SL',
dk_sal: 6500,
dk_pts: 16.9,
fd_sal: 7000,
fd_pts: 14.1,
y_sal: 22,
y_pts: 14.1,
rating: 'None',
},
{
id: 1127,
name: 'Cooper Rush',
team: 'DAL',
pos: 'QB',
num: '',
opp: 'NO 32nd',
game_time: 'SN',
dk_sal: 4200,
dk_pts: 0.2,
fd_sal: 6000,
fd_pts: 0.2,
y_sal: 20,
y_pts: 0.2,
rating: 'None',
},
{
id: 1128,
name: 'Cordarrelle Patterson',
team: 'CHI',
pos: 'WR',
num: '#84',
opp: 'MIN 20th',
game_time: 'SL',
dk_sal: 3600,
dk_pts: 4.8,
fd_sal: 4500,
fd_pts: 4.3,
y_sal: 10,
y_pts: 4.3,
rating: 'None',
},
{
id: 1129,
name: 'Corey Clement',
team: 'PHI',
pos: 'RB',
num: '#30',
opp: 'GB 29th',
game_time: 'T',
dk_sal: 3000,
dk_pts: 1.9,
fd_sal: 4500,
fd_pts: 1.7,
y_sal: 10,
y_pts: 1.7,
rating: 'None',
},
{
id: 1130,
name: 'Corey Davis',
team: 'TEN',
pos: 'WR',
num: '#84',
opp: 'ATL 12th',
game_time: 'SE',
dk_sal: 4200,
dk_pts: 10,
fd_sal: 5200,
fd_pts: 8.3,
y_sal: 10,
y_pts: 8.3,
rating: 'None',
},
{
id: 1131,
name: 'Courtland Sutton',
team: 'DEN',
pos: 'WR',
num: '#14',
opp: 'JAX 19th',
game_time: 'SL',
dk_sal: 4600,
dk_pts: 11.1,
fd_sal: 5700,
fd_pts: 9.2,
y_sal: 16,
y_pts: 9.2,
rating: 'None',
},
{
id: 1133,
name: 'Cullen Gillaspia',
team: 'HOU',
pos: 'RB',
num: '',
opp: 'CAR 22nd',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 0.2,
fd_sal: 4500,
fd_pts: 0.2,
y_sal: 10,
y_pts: 0.2,
rating: 'None',
},
{
id: 1134,
name: 'Curtis Samuel',
team: 'CAR',
pos: 'WR',
num: '#10',
opp: 'HOU 24th',
game_time: 'SE',
dk_sal: 4600,
dk_pts: 11.3,
fd_sal: 5800,
fd_pts: 9.3,
y_sal: 16,
y_pts: 9.3,
rating: 'None',
},
{
id: 1135,
name: 'DJ Chark',
team: 'JAX',
pos: 'WR',
num: '#17',
opp: 'DEN 5th',
game_time: 'SL',
dk_sal: 5100,
dk_pts: 10.6,
fd_sal: 6000,
fd_pts: 8.8,
y_sal: 15,
y_pts: 8.8,
rating: 'None',
},
{
id: 1136,
name: 'DJ Moore',
team: 'CAR',
pos: 'WR',
num: '#12',
opp: 'HOU 24th',
game_time: 'SE',
dk_sal: 5600,
dk_pts: 13.7,
fd_sal: 6400,
fd_pts: 11.1,
y_sal: 16,
y_pts: 11.1,
rating: 'None',
},
{
id: 1137,
name: 'DK Metcalf',
team: 'SEA',
pos: 'WR',
num: '#14',
opp: 'ARI 16th',
game_time: 'SL',
dk_sal: 4800,
dk_pts: 10.6,
fd_sal: 6100,
fd_pts: 9,
y_sal: 15,
y_pts: 9,
rating: 'None',
},
{
id: 1138,
name: 'Daesean Hamilton',
team: 'DEN',
pos: 'WR',
num: '#17',
opp: 'JAX 19th',
game_time: 'SL',
dk_sal: 3600,
dk_pts: 5,
fd_sal: 5000,
fd_pts: 3.9,
y_sal: 10,
y_pts: 3.9,
rating: 'None',
},
{
id: 1139,
name: 'Dak Prescott',
team: 'DAL',
pos: 'QB',
num: '#4',
opp: 'NO 32nd',
game_time: 'SN',
dk_sal: 6300,
dk_pts: 20.1,
fd_sal: 8400,
fd_pts: 20.1,
y_sal: 39,
y_pts: 20.1,
rating: 'None',
},
{
id: 1140,
name: 'Cowboys',
team: 'DAL',
pos: 'DST',
num: '',
opp: 'NO 21st',
game_time: 'SN',
dk_sal: 3000,
dk_pts: 6.1,
fd_sal: 5000,
fd_pts: 6.1,
y_sal: 15,
y_pts: 6.1,
rating: 'None',
},
{
id: 1141,
name: 'Dallas Goedert',
team: 'PHI',
pos: 'TE',
num: '#88',
opp: 'GB 1st',
game_time: 'T',
dk_sal: 2800,
dk_pts: 4.5,
fd_sal: 4500,
fd_pts: 3.5,
y_sal: 10,
y_pts: 3.5,
rating: 'None',
},
{
id: 1142,
name: 'Dalton Schultz',
team: 'DAL',
pos: 'TE',
num: '',
opp: 'NO 12th',
game_time: 'SN',
dk_sal: 2500,
dk_pts: 0.6,
fd_sal: 4000,
fd_pts: 0.5,
y_sal: 10,
y_pts: 0.5,
rating: 'None',
},
{
id: 1143,
name: 'Dalvin Cook',
team: 'MIN',
pos: 'RB',
num: '#33',
opp: 'CHI 4th',
game_time: 'SL',
dk_sal: 8300,
dk_pts: 16.4,
fd_sal: 8300,
fd_pts: 14.5,
y_sal: 37,
y_pts: 14.5,
rating: 'None',
},
{
id: 1146,
name: 'Damien Harris',
team: 'NE',
pos: 'RB',
num: '',
opp: 'BUF 18th',
game_time: 'SE',
dk_sal: 3300,
dk_pts: 1.8,
fd_sal: 4500,
fd_pts: 1.6,
y_sal: 10,
y_pts: 1.6,
rating: 'None',
},
{
id: 1147,
name: 'Damien Williams',
team: 'KC',
pos: 'RB',
num: '#26',
opp: 'DET 26th',
game_time: 'SE',
dk_sal: 5400,
dk_pts: 9.9,
fd_sal: 6400,
fd_pts: 8.5,
y_sal: 25,
y_pts: 8.5,
rating: 'None',
},
{
id: 1148,
name: 'Damiere Byrd',
team: 'ARI',
pos: 'WR',
num: '#14',
opp: 'SEA 9th',
game_time: 'SL',
dk_sal: 3300,
dk_pts: 6.2,
fd_sal: 4700,
fd_pts: 5.1,
y_sal: 11,
y_pts: 5.1,
rating: 'None',
},
{
id: 1149,
name: 'Damion Ratley',
team: 'CLE',
pos: 'WR',
num: '#18',
opp: 'BAL 23rd',
game_time: 'SE',
dk_sal: 3500,
dk_pts: 5.9,
fd_sal: 4500,
fd_pts: 4.8,
y_sal: 10,
y_pts: 4.8,
rating: 'None',
},
{
id: 1150,
name: 'Damion Willis',
team: 'CIN',
pos: 'WR',
num: '#15',
opp: 'PIT 26th',
game_time: 'M',
dk_sal: 3000,
dk_pts: 3.5,
fd_sal: 4500,
fd_pts: 2.8,
y_sal: 10,
y_pts: 2.8,
rating: 'None',
},
{
id: 1152,
name: 'Dan Vitale',
team: 'GB',
pos: 'RB',
num: '',
opp: 'PHI 5th',
game_time: 'T',
dk_sal: 3000,
dk_pts: 0.7,
fd_sal: 4500,
fd_pts: 0.6,
y_sal: 10,
y_pts: 0.6,
rating: 'None',
},
{
id: 1153,
name: 'Daniel Jones',
team: 'NYG',
pos: 'QB',
num: '#8',
opp: 'WAS 28th',
game_time: 'SE',
dk_sal: 5300,
dk_pts: 17.8,
fd_sal: 7300,
fd_pts: 17.7,
y_sal: 29,
y_pts: 17.7,
rating: 'None',
},
{
id: 1154,
name: 'Danny Amendola',
team: 'DET',
pos: 'WR',
num: '#80',
opp: 'KC 18th',
game_time: 'SE',
dk_sal: 3900,
dk_pts: 9.9,
fd_sal: 4900,
fd_pts: 7.8,
y_sal: 14,
y_pts: 7.8,
rating: 'None',
},
{
id: 1156,
name: 'Dare Ogunbowale',
team: 'TB',
pos: 'RB',
num: '#44',
opp: 'LAR 23rd',
game_time: 'SL',
dk_sal: 3400,
dk_pts: 4.2,
fd_sal: 4500,
fd_pts: 3.3,
y_sal: 10,
y_pts: 3.3,
rating: 'None',
},
{
id: 1157,
name: 'Darius Jennings',
team: 'TEN',
pos: 'WR',
num: '',
opp: 'ATL 12th',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 1,
fd_sal: 4500,
fd_pts: 0.8,
y_sal: 10,
y_pts: 0.8,
rating: 'None',
},
{
id: 1158,
name: 'Darius Slayton',
team: 'NYG',
pos: 'WR',
num: '#86',
opp: 'WAS 31st',
game_time: 'SE',
dk_sal: 3200,
dk_pts: 3.8,
fd_sal: 4800,
fd_pts: 3,
y_sal: 10,
y_pts: 3,
rating: 'None',
},
{
id: 1159,
name: 'Darrel Williams',
team: 'KC',
pos: 'RB',
num: '#31',
opp: 'DET 26th',
game_time: 'SE',
dk_sal: 4700,
dk_pts: 4.5,
fd_sal: 5500,
fd_pts: 4.2,
y_sal: 16,
y_pts: 4.2,
rating: 'None',
},
{
id: 1160,
name: 'Darrell Daniels',
team: 'ARI',
pos: 'TE',
num: '',
opp: 'SEA 24th',
game_time: 'SL',
dk_sal: 2600,
dk_pts: 0.1,
fd_sal: 4000,
fd_pts: 0.1,
y_sal: 10,
y_pts: 0.1,
rating: 'None',
},
{
id: 1161,
name: 'Darrell Henderson',
team: 'LAR',
pos: 'RB',
num: '',
opp: 'TB 2nd',
game_time: 'SL',
dk_sal: 3500,
dk_pts: 0.6,
fd_sal: 4700,
fd_pts: 0.6,
y_sal: 10,
y_pts: 0.6,
rating: 'None',
},
{
id: 1162,
name: 'Darren Fells',
team: 'HOU',
pos: 'TE',
num: '#87',
opp: 'CAR 7th',
game_time: 'SE',
dk_sal: 2800,
dk_pts: 3.9,
fd_sal: 4700,
fd_pts: 3.3,
y_sal: 10,
y_pts: 3.3,
rating: 'None',
},
{
id: 1163,
name: 'Darren Sproles',
team: 'PHI',
pos: 'RB',
num: '#43',
opp: 'GB 29th',
game_time: 'T',
dk_sal: 3500,
dk_pts: 5.9,
fd_sal: 4600,
fd_pts: 4.9,
y_sal: 14,
y_pts: 4.9,
rating: 'None',
},
{
id: 1164,
name: 'Darren Waller',
team: 'OAK',
pos: 'TE',
num: '#83',
opp: 'IND 28th',
game_time: 'SE',
dk_sal: 5200,
dk_pts: 12.6,
fd_sal: 6700,
fd_pts: 10,
y_sal: 18,
y_pts: 10,
rating: 'None',
},
{
id: 1165,
name: 'Darrius Shepherd',
team: 'GB',
pos: 'WR',
num: '',
opp: 'PHI 29th',
game_time: 'T',
dk_sal: 3000,
dk_pts: 1.5,
fd_sal: 4500,
fd_pts: 1.3,
y_sal: 10,
y_pts: 1.3,
rating: 'None',
},
{
id: 1166,
name: 'Darwin Thompson',
team: 'KC',
pos: 'RB',
num: '#34',
opp: 'DET 26th',
game_time: 'SE',
dk_sal: 4300,
dk_pts: 3.7,
fd_sal: 4500,
fd_pts: 3.4,
y_sal: 11,
y_pts: 3.4,
rating: 'None',
},
{
id: 1167,
name: 'Davante Adams',
team: 'GB',
pos: 'WR',
num: '#17',
opp: 'PHI 29th',
game_time: 'T',
dk_sal: 7500,
dk_pts: 18.6,
fd_sal: 8000,
fd_pts: 15.3,
y_sal: 28,
y_pts: 15.3,
rating: 'None',
},
{
id: 1169,
name: 'David Fluellen',
team: 'TEN',
pos: 'RB',
num: '',
opp: 'ATL 16th',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 0.2,
fd_sal: 4500,
fd_pts: 0.2,
y_sal: 10,
y_pts: 0.2,
rating: 'None',
},
{
id: 1170,
name: 'David Johnson',
team: 'ARI',
pos: 'RB',
num: '#31',
opp: 'SEA 17th',
game_time: 'SL',
dk_sal: 6800,
dk_pts: 17.3,
fd_sal: 6800,
fd_pts: 15.3,
y_sal: 24,
y_pts: 15.3,
rating: 'None',
},
{
id: 1171,
name: 'David Montgomery',
team: 'CHI',
pos: 'RB',
num: '#32',
opp: 'MIN 9th',
game_time: 'SL',
dk_sal: 5300,
dk_pts: 12.3,
fd_sal: 6000,
fd_pts: 11.3,
y_sal: 18,
y_pts: 11.3,
rating: 'None',
},
{
id: 1172,
name: 'David Moore',
team: 'SEA',
pos: 'WR',
num: '#83',
opp: 'ARI 16th',
game_time: 'SL',
dk_sal: 3400,
dk_pts: 6.5,
fd_sal: 4800,
fd_pts: 5.6,
y_sal: 12,
y_pts: 5.6,
rating: 'None',
},
{
id: 1176,
name: 'Dawson Knox',
team: 'BUF',
pos: 'TE',
num: '#88',
opp: 'NE 4th',
game_time: 'SE',
dk_sal: 2900,
dk_pts: 4.1,
fd_sal: 4700,
fd_pts: 3.2,
y_sal: 11,
y_pts: 3.2,
rating: 'None',
},
{
id: 1177,
name: 'Deandre Carter',
team: 'HOU',
pos: 'WR',
num: '',
opp: 'CAR 7th',
game_time: 'SE',
dk_sal: 3100,
dk_pts: 0.8,
fd_sal: 4500,
fd_pts: 0.6,
y_sal: 10,
y_pts: 0.6,
rating: 'None',
},
{
id: 1178,
name: 'DeAndre Hopkins',
team: 'HOU',
pos: 'WR',
num: '#10',
opp: 'CAR 7th',
game_time: 'SE',
dk_sal: 7700,
dk_pts: 19.2,
fd_sal: 8700,
fd_pts: 15.8,
y_sal: 38,
y_pts: 15.8,
rating: 'None',
},
{
id: 1179,
name: 'Deandre Washington',
team: 'OAK',
pos: 'RB',
num: '',
opp: 'IND 28th',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 2.6,
fd_sal: 4500,
fd_pts: 2.2,
y_sal: 14,
y_pts: 2.2,
rating: 'None',
},
{
id: 1181,
name: 'DeAnthony Thomas',
team: 'KC',
pos: 'WR',
num: '',
opp: 'DET 22nd',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 1.7,
fd_sal: 4500,
fd_pts: 1.3,
y_sal: 10,
y_pts: 1.3,
rating: 'None',
},
{
id: 1182,
name: 'Dede Westbrook',
team: 'JAX',
pos: 'WR',
num: '#12',
opp: 'DEN 5th',
game_time: 'SL',
dk_sal: 5500,
dk_pts: 10.4,
fd_sal: 5300,
fd_pts: 8.4,
y_sal: 14,
y_pts: 8.4,
rating: 'None',
},
{
id: 1184,
name: 'Delanie Walker',
team: 'TEN',
pos: 'TE',
num: '#82',
opp: 'ATL 16th',
game_time: 'SE',
dk_sal: 4800,
dk_pts: 10.4,
fd_sal: 5700,
fd_pts: 8.3,
y_sal: 19,
y_pts: 8.3,
rating: 'None',
},
{
id: 1185,
name: 'Demarcus Robinson',
team: 'KC',
pos: 'WR',
num: '#11',
opp: 'DET 22nd',
game_time: 'SE',
dk_sal: 5200,
dk_pts: 10.4,
fd_sal: 6700,
fd_pts: 8.9,
y_sal: 17,
y_pts: 8.9,
rating: 'None',
},
{
id: 1186,
name: 'Demetrius Harris',
team: 'CLE',
pos: 'TE',
num: '#88',
opp: 'BAL 10th',
game_time: 'SE',
dk_sal: 2500,
dk_pts: 4.3,
fd_sal: 4500,
fd_pts: 3.4,
y_sal: 10,
y_pts: 3.4,
rating: 'None',
},
{
id: 1187,
name: 'Broncos',
team: 'DEN',
pos: 'DST',
num: '',
opp: 'JAX 23rd',
game_time: 'SL',
dk_sal: 2900,
dk_pts: 8,
fd_sal: 4100,
fd_pts: 8,
y_sal: 10,
y_pts: 8,
rating: 'None',
},
{
id: 1188,
name: 'Deon Cain',
team: 'IND',
pos: 'WR',
num: '#11',
opp: 'OAK 27th',
game_time: 'SE',
dk_sal: 3200,
dk_pts: 5.7,
fd_sal: 4500,
fd_pts: 4.7,
y_sal: 10,
y_pts: 4.7,
rating: 'None',
},
{
id: 1192,
name: 'Derek Carr',
team: 'OAK',
pos: 'QB',
num: '#4',
opp: 'IND 26th',
game_time: 'SE',
dk_sal: 5300,
dk_pts: 15.5,
fd_sal: 6800,
fd_pts: 15.5,
y_sal: 26,
y_pts: 15.5,
rating: 'None',
},
{
id: 1193,
name: 'Derek Carrier',
team: 'OAK',
pos: 'TE',
num: '#85',
opp: 'IND 28th',
game_time: 'SE',
dk_sal: 2500,
dk_pts: 3.1,
fd_sal: 4000,
fd_pts: 2.4,
y_sal: 10,
y_pts: 2.4,
rating: 'None',
},
{
id: 1194,
name: 'Derek Watt',
team: 'LAC',
pos: 'RB',
num: '',
opp: 'MIA 32nd',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 0.9,
fd_sal: 4500,
fd_pts: 0.7,
y_sal: 10,
y_pts: 0.7,
rating: 'None',
},
{
id: 1195,
name: 'DErnest Johnson',
team: 'CLE',
pos: 'RB',
num: '',
opp: 'BAL 7th',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 1.8,
fd_sal: 4700,
fd_pts: 1.4,
y_sal: 10,
y_pts: 1.4,
rating: 'None',
},
{
id: 1196,
name: 'Derrick Henry',
team: 'TEN',
pos: 'RB',
num: '#22',
opp: 'ATL 16th',
game_time: 'SE',
dk_sal: 6300,
dk_pts: 14.9,
fd_sal: 7000,
fd_pts: 14.2,
y_sal: 31,
y_pts: 14.2,
rating: 'None',
},
{
id: 1200,
name: 'Deshaun Watson',
team: 'HOU',
pos: 'QB',
num: '#4',
opp: 'CAR 8th',
game_time: 'SE',
dk_sal: 6400,
dk_pts: 22.2,
fd_sal: 8200,
fd_pts: 22.1,
y_sal: 34,
y_pts: 22.1,
rating: 'None',
},
{
id: 1203,
name: 'Lions',
team: 'DET',
pos: 'DST',
num: '',
opp: 'KC 2nd',
game_time: 'SE',
dk_sal: 2200,
dk_pts: 4.3,
fd_sal: 3100,
fd_pts: 4.3,
y_sal: 13,
y_pts: 4.3,
rating: 'None',
},
{
id: 1204,
name: 'Devante Parker',
team: 'MIA',
pos: 'WR',
num: '#11',
opp: 'LAC 16th',
game_time: 'SE',
dk_sal: 4100,
dk_pts: 8.2,
fd_sal: 5000,
fd_pts: 6.7,
y_sal: 11,
y_pts: 6.7,
rating: 'None',
},
{
id: 1205,
name: 'Devin Singletary',
team: 'BUF',
pos: 'RB',
num: '#40',
opp: 'NE 1st',
game_time: 'SE',
dk_sal: 4200,
dk_pts: 8.5,
fd_sal: 5400,
fd_pts: 7.4,
y_sal: 15,
y_pts: 7.4,
rating: 'None',
},
{
id: 1206,
name: 'Devin Smith',
team: 'DAL',
pos: 'WR',
num: '#15',
opp: 'NO 30th',
game_time: 'SN',
dk_sal: 3700,
dk_pts: 7.4,
fd_sal: 4900,
fd_pts: 6.1,
y_sal: 14,
y_pts: 6.1,
rating: 'None',
},
{
id: 1208,
name: 'Devonta Freeman',
team: 'ATL',
pos: 'RB',
num: '#24',
opp: 'TEN 8th',
game_time: 'SE',
dk_sal: 5000,
dk_pts: 13.7,
fd_sal: 6200,
fd_pts: 12.2,
y_sal: 18,
y_pts: 12.2,
rating: 'None',
},
{
id: 1209,
name: 'Devontae Booker',
team: 'DEN',
pos: 'RB',
num: '',
opp: 'JAX 13th',
game_time: 'SL',
dk_sal: 3100,
dk_pts: 0.9,
fd_sal: 4500,
fd_pts: 0.8,
y_sal: 10,
y_pts: 0.8,
rating: 'None',
},
{
id: 1210,
name: 'Dexter Williams',
team: 'GB',
pos: 'RB',
num: '',
opp: 'PHI 5th',
game_time: 'T',
dk_sal: 3000,
dk_pts: 1.4,
fd_sal: 4500,
fd_pts: 1.3,
y_sal: 10,
y_pts: 1.3,
rating: 'None',
},
{
id: 1212,
name: 'Dion Lewis',
team: 'TEN',
pos: 'RB',
num: '#33',
opp: 'ATL 16th',
game_time: 'SE',
dk_sal: 3700,
dk_pts: 6.9,
fd_sal: 4900,
fd_pts: 5.7,
y_sal: 11,
y_pts: 5.7,
rating: 'None',
},
{
id: 1213,
name: 'Diontae Johnson',
team: 'PIT',
pos: 'WR',
num: '#18',
opp: 'CIN 14th',
game_time: 'M',
dk_sal: 4400,
dk_pts: 9.2,
fd_sal: 5000,
fd_pts: 7.6,
y_sal: 10,
y_pts: 7.6,
rating: 'None',
},
{
id: 1215,
name: 'DJ Foster',
team: 'ARI',
pos: 'RB',
num: '',
opp: 'SEA 17th',
game_time: 'SL',
dk_sal: 3000,
dk_pts: 0.4,
fd_sal: 4500,
fd_pts: 0.3,
y_sal: 10,
y_pts: 0.3,
rating: 'None',
},
{
id: 1216,
name: 'Donte Moncrief',
team: 'PIT',
pos: 'WR',
num: '',
opp: 'CIN 14th',
game_time: 'M',
dk_sal: 3600,
dk_pts: 2.9,
fd_sal: 4900,
fd_pts: 2.4,
y_sal: 10,
y_pts: 2.4,
rating: 'None',
},
{
id: 1217,
name: 'Dontrell Hilliard',
team: 'CLE',
pos: 'RB',
num: '',
opp: 'BAL 7th',
game_time: 'SE',
dk_sal: 3500,
dk_pts: 2.6,
fd_sal: 4800,
fd_pts: 2,
y_sal: 10,
y_pts: 2,
rating: 'None',
},
{
id: 1218,
name: 'Dontrelle Inman',
team: 'LAC',
pos: 'WR',
num: '#15',
opp: 'MIA 28th',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 6.6,
fd_sal: 4800,
fd_pts: 5.6,
y_sal: 10,
y_pts: 5.6,
rating: 'None',
},
{
id: 1221,
name: 'Drew Sample',
team: 'CIN',
pos: 'TE',
num: '',
opp: 'PIT 22nd',
game_time: 'M',
dk_sal: 2500,
dk_pts: 0.7,
fd_sal: 4000,
fd_pts: 0.6,
y_sal: 10,
y_pts: 0.6,
rating: 'None',
},
{
id: 1223,
name: 'Duke Johnson',
team: 'HOU',
pos: 'RB',
num: '#25',
opp: 'CAR 22nd',
game_time: 'SE',
dk_sal: 4200,
dk_pts: 9.4,
fd_sal: 5300,
fd_pts: 7.8,
y_sal: 14,
y_pts: 7.8,
rating: 'None',
},
{
id: 1224,
name: 'Durham Smythe',
team: 'MIA',
pos: 'TE',
num: '',
opp: 'LAC 31st',
game_time: 'SE',
dk_sal: 2600,
dk_pts: 2.1,
fd_sal: 4000,
fd_pts: 1.8,
y_sal: 10,
y_pts: 1.8,
rating: 'None',
},
{
id: 1225,
name: 'Dwayne Haskins',
team: 'WAS',
pos: 'QB',
num: '',
opp: 'NYG 29th',
game_time: 'SE',
dk_sal: 4700,
dk_pts: 0.9,
fd_sal: 6000,
fd_pts: 0.9,
y_sal: 20,
y_pts: 0.9,
rating: 'None',
},
{
id: 1226,
name: 'Dwayne Washington',
team: 'NO',
pos: 'RB',
num: '',
opp: 'DAL 12th',
game_time: 'SN',
dk_sal: 3000,
dk_pts: 0.6,
fd_sal: 4500,
fd_pts: 0.5,
y_sal: 10,
y_pts: 0.5,
rating: 'None',
},
{
id: 1228,
name: 'Eli Manning',
team: 'NYG',
pos: 'QB',
num: '#10',
opp: 'WAS 28th',
game_time: 'SE',
dk_sal: 4800,
dk_pts: 0.6,
fd_sal: 6000,
fd_pts: 0.5,
y_sal: 22,
y_pts: 0.5,
rating: 'None',
},
{
id: 1231,
name: 'Elijhaa Penny',
team: 'NYG',
pos: 'RB',
num: '',
opp: 'WAS 11th',
game_time: 'SE',
dk_sal: 4300,
dk_pts: 2.6,
fd_sal: 5000,
fd_pts: 2.4,
y_sal: 10,
y_pts: 2.4,
rating: 'None',
},
{
id: 1234,
name: 'Emmanuel Sanders',
team: 'DEN',
pos: 'WR',
num: '#10',
opp: 'JAX 19th',
game_time: 'SL',
dk_sal: 4900,
dk_pts: 12.7,
fd_sal: 5500,
fd_pts: 10.2,
y_sal: 20,
y_pts: 10.2,
rating: 'None',
},
{
id: 1235,
name: 'Eric Ebron',
team: 'IND',
pos: 'TE',
num: '#85',
opp: 'OAK 24th',
game_time: 'SE',
dk_sal: 4000,
dk_pts: 8.4,
fd_sal: 5300,
fd_pts: 6.9,
y_sal: 16,
y_pts: 6.9,
rating: 'None',
},
{
id: 1237,
name: 'Eric Tomlinson',
team: 'NYG',
pos: 'TE',
num: '',
opp: 'WAS 14th',
game_time: 'SE',
dk_sal: 2500,
dk_pts: 0.6,
fd_sal: 4000,
fd_pts: 0.4,
y_sal: 10,
y_pts: 0.4,
rating: 'None',
},
{
id: 1239,
name: 'Evan Engram',
team: 'NYG',
pos: 'TE',
num: '#88',
opp: 'WAS 14th',
game_time: 'SE',
dk_sal: 5700,
dk_pts: 15.4,
fd_sal: 6800,
fd_pts: 12.6,
y_sal: 26,
y_pts: 12.6,
rating: 'None',
},
{
id: 1240,
name: 'Ezekiel Elliott',
team: 'DAL',
pos: 'RB',
num: '#21',
opp: 'NO 6th',
game_time: 'SN',
dk_sal: 8900,
dk_pts: 19.6,
fd_sal: 8800,
fd_pts: 17.7,
y_sal: 34,
y_pts: 17.7,
rating: 'None',
},
{
id: 1241,
name: 'Foster Moreau',
team: 'OAK',
pos: 'TE',
num: '',
opp: 'IND 28th',
game_time: 'SE',
dk_sal: 2500,
dk_pts: 2.7,
fd_sal: 4000,
fd_pts: 2.2,
y_sal: 10,
y_pts: 2.2,
rating: 'None',
},
{
id: 1242,
name: 'Frank Gore',
team: 'BUF',
pos: 'RB',
num: '#20',
opp: 'NE 1st',
game_time: 'SE',
dk_sal: 4400,
dk_pts: 9.3,
fd_sal: 5700,
fd_pts: 8.6,
y_sal: 14,
y_pts: 8.6,
rating: 'None',
},
{
id: 1244,
name: 'Gardner Minshew',
team: 'JAX',
pos: 'QB',
num: '#15',
opp: 'DEN 4th',
game_time: 'SL',
dk_sal: 5200,
dk_pts: 14.2,
fd_sal: 6700,
fd_pts: 14.2,
y_sal: 22,
y_pts: 14.2,
rating: 'None',
},
{
id: 1247,
name: 'Gary Jennings Jr',
team: 'SEA',
pos: 'WR',
num: '',
opp: 'ARI 16th',
game_time: 'SL',
dk_sal: 3000,
dk_pts: 1.3,
fd_sal: 4500,
fd_pts: 1.2,
y_sal: 10,
y_pts: 1.2,
rating: 'None',
},
{
id: 1249,
name: 'Geoff Swaim',
team: 'JAX',
pos: 'TE',
num: '#87',
opp: 'DEN 11th',
game_time: 'SL',
dk_sal: 2900,
dk_pts: 4.3,
fd_sal: 4500,
fd_pts: 3.4,
y_sal: 10,
y_pts: 3.4,
rating: 'None',
},
{
id: 1250,
name: 'Gerald Everett',
team: 'LAR',
pos: 'TE',
num: '#81',
opp: 'TB 30th',
game_time: 'SL',
dk_sal: 3200,
dk_pts: 6,
fd_sal: 4500,
fd_pts: 4.9,
y_sal: 10,
y_pts: 4.9,
rating: 'None',
},
{
id: 1251,
name: 'Geremy Davis',
team: 'LAC',
pos: 'WR',
num: '',
opp: 'MIA 28th',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 1.7,
fd_sal: 4500,
fd_pts: 1.4,
y_sal: 10,
y_pts: 1.4,
rating: 'None',
},
{
id: 1252,
name: 'Geronimo Allison',
team: 'GB',
pos: 'WR',
num: '#81',
opp: 'PHI 29th',
game_time: 'T',
dk_sal: 4500,
dk_pts: 6.4,
fd_sal: 4900,
fd_pts: 5,
y_sal: 13,
y_pts: 5,
rating: 'None',
},
{
id: 1253,
name: 'Giovani Bernard',
team: 'CIN',
pos: 'RB',
num: '#25',
opp: 'PIT 30th',
game_time: 'M',
dk_sal: 4000,
dk_pts: 7.2,
fd_sal: 4800,
fd_pts: 5.9,
y_sal: 12,
y_pts: 5.9,
rating: 'None',
},
{
id: 1255,
name: 'Packers',
team: 'GB',
pos: 'DST',
num: '',
opp: 'PHI 13th',
game_time: 'T',
dk_sal: 3100,
dk_pts: 8.4,
fd_sal: 3800,
fd_pts: 8.4,
y_sal: 16,
y_pts: 8.4,
rating: 'None',
},
{
id: 1256,
name: 'Greg Olsen',
team: 'CAR',
pos: 'TE',
num: '#88',
opp: 'HOU 5th',
game_time: 'SE',
dk_sal: 4200,
dk_pts: 11.1,
fd_sal: 6000,
fd_pts: 9.1,
y_sal: 19,
y_pts: 9.1,
rating: 'None',
},
{
id: 1258,
name: 'Gregory Howell Jr',
team: 'HOU',
pos: 'RB',
num: '',
opp: 'CAR 22nd',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 0.5,
fd_sal: 4500,
fd_pts: 0.4,
y_sal: 10,
y_pts: 0.4,
rating: 'None',
},
{
id: 1260,
name: 'Gus Edwards',
team: 'BAL',
pos: 'RB',
num: '#35',
opp: 'CLE 14th',
game_time: 'SE',
dk_sal: 3600,
dk_pts: 4.4,
fd_sal: 5300,
fd_pts: 4.2,
y_sal: 16,
y_pts: 4.2,
rating: 'None',
},
{
id: 1263,
name: 'Hayden Hurst',
team: 'BAL',
pos: 'TE',
num: '#81',
opp: 'CLE 15th',
game_time: 'SE',
dk_sal: 3300,
dk_pts: 6,
fd_sal: 4400,
fd_pts: 4.8,
y_sal: 10,
y_pts: 4.8,
rating: 'None',
},
{
id: 1264,
name: 'Texans',
team: 'HOU',
pos: 'DST',
num: '',
opp: 'CAR 11th',
game_time: 'SE',
dk_sal: 3100,
dk_pts: 8.2,
fd_sal: 4400,
fd_pts: 8.2,
y_sal: 16,
y_pts: 8.2,
rating: 'None',
},
{
id: 1266,
name: 'Hunter Renfrow',
team: 'OAK',
pos: 'WR',
num: '#13',
opp: 'IND 8th',
game_time: 'SE',
dk_sal: 3800,
dk_pts: 7.3,
fd_sal: 4900,
fd_pts: 5.9,
y_sal: 10,
y_pts: 5.9,
rating: 'None',
},
{
id: 1267,
name: 'Ian Thomas',
team: 'CAR',
pos: 'TE',
num: '#80',
opp: 'HOU 5th',
game_time: 'SE',
dk_sal: 2600,
dk_pts: 0.6,
fd_sal: 4000,
fd_pts: 0.5,
y_sal: 10,
y_pts: 0.5,
rating: 'None',
},
{
id: 1268,
name: 'Colts',
team: 'IND',
pos: 'DST',
num: '',
opp: 'OAK 26th',
game_time: 'SE',
dk_sal: 3100,
dk_pts: 8.2,
fd_sal: 4200,
fd_pts: 8.2,
y_sal: 14,
y_pts: 8.2,
rating: 'None',
},
{
id: 1269,
name: 'Irv Smith Jr',
team: 'MIN',
pos: 'TE',
num: '#84',
opp: 'CHI 21st',
game_time: 'SL',
dk_sal: 2900,
dk_pts: 4.1,
fd_sal: 4600,
fd_pts: 3.2,
y_sal: 12,
y_pts: 3.2,
rating: 'None',
},
{
id: 1272,
name: 'Isaiah McKenzie',
team: 'BUF',
pos: 'WR',
num: '#19',
opp: 'NE 1st',
game_time: 'SE',
dk_sal: 3100,
dk_pts: 3.1,
fd_sal: 4500,
fd_pts: 2.5,
y_sal: 10,
y_pts: 2.5,
rating: 'None',
},
{
id: 1273,
name: 'Ito Smith',
team: 'ATL',
pos: 'RB',
num: '#25',
opp: 'TEN 8th',
game_time: 'SE',
dk_sal: 3700,
dk_pts: 5.3,
fd_sal: 4800,
fd_pts: 4.4,
y_sal: 11,
y_pts: 4.4,
rating: 'None',
},
{
id: 1275,
name: 'Jack Doyle',
team: 'IND',
pos: 'TE',
num: '#84',
opp: 'OAK 24th',
game_time: 'SE',
dk_sal: 3700,
dk_pts: 7.8,
fd_sal: 5200,
fd_pts: 6.2,
y_sal: 13,
y_pts: 6.2,
rating: 'None',
},
{
id: 1276,
name: 'Jaguars',
team: 'JAX',
pos: 'DST',
num: '',
opp: 'DEN 20th',
game_time: 'SL',
dk_sal: 3700,
dk_pts: 7.5,
fd_sal: 4700,
fd_pts: 7.5,
y_sal: 13,
y_pts: 7.5,
rating: 'None',
},
{
id: 1279,
name: 'Jacoby Brissett',
team: 'IND',
pos: 'QB',
num: '#7',
opp: 'OAK 24th',
game_time: 'SE',
dk_sal: 5400,
dk_pts: 17.7,
fd_sal: 7300,
fd_pts: 17.7,
y_sal: 30,
y_pts: 17.7,
rating: 'None',
},
{
id: 1283,
name: 'Jake Kumerow',
team: 'GB',
pos: 'WR',
num: '',
opp: 'PHI 29th',
game_time: 'T',
dk_sal: 3000,
dk_pts: 2,
fd_sal: 4500,
fd_pts: 1.7,
y_sal: 10,
y_pts: 1.7,
rating: 'None',
},
{
id: 1285,
name: 'Jakeem Grant',
team: 'MIA',
pos: 'WR',
num: '#19',
opp: 'LAC 16th',
game_time: 'SE',
dk_sal: 3500,
dk_pts: 6.6,
fd_sal: 4500,
fd_pts: 5.4,
y_sal: 10,
y_pts: 5.4,
rating: 'None',
},
{
id: 1287,
name: 'Jakobi Meyers',
team: 'NE',
pos: 'WR',
num: '',
opp: 'BUF 4th',
game_time: 'SE',
dk_sal: 3500,
dk_pts: 3,
fd_sal: 4500,
fd_pts: 2.5,
y_sal: 10,
y_pts: 2.5,
rating: 'None',
},
{
id: 1289,
name: 'Jalen Richard',
team: 'OAK',
pos: 'RB',
num: '#30',
opp: 'IND 28th',
game_time: 'SE',
dk_sal: 4000,
dk_pts: 6.6,
fd_sal: 5000,
fd_pts: 5.3,
y_sal: 12,
y_pts: 5.3,
rating: 'None',
},
{
id: 1290,
name: 'Jamaal Williams',
team: 'GB',
pos: 'RB',
num: '#30',
opp: 'PHI 5th',
game_time: 'T',
dk_sal: 4300,
dk_pts: 8.8,
fd_sal: 5400,
fd_pts: 7.7,
y_sal: 15,
y_pts: 7.7,
rating: 'None',
},
{
id: 1291,
name: 'Jameis Winston',
team: 'TB',
pos: 'QB',
num: '#3',
opp: 'LAR 3rd',
game_time: 'SL',
dk_sal: 5700,
dk_pts: 19.8,
fd_sal: 7500,
fd_pts: 19.8,
y_sal: 25,
y_pts: 19.8,
rating: 'None',
},
{
id: 1292,
name: 'James Conner',
team: 'PIT',
pos: 'RB',
num: '#30',
opp: 'CIN 31st',
game_time: 'M',
dk_sal: 6500,
dk_pts: 17.1,
fd_sal: 6900,
fd_pts: 15.2,
y_sal: 28,
y_pts: 15.2,
rating: 'None',
},
{
id: 1294,
name: 'James OShaughnessy',
team: 'JAX',
pos: 'TE',
num: '#80',
opp: 'DEN 11th',
game_time: 'SL',
dk_sal: 3000,
dk_pts: 5.2,
fd_sal: 4600,
fd_pts: 4.1,
y_sal: 11,
y_pts: 4.1,
rating: 'None',
},
{
id: 1295,
name: 'James Washington',
team: 'PIT',
pos: 'WR',
num: '#13',
opp: 'CIN 14th',
game_time: 'M',
dk_sal: 4300,
dk_pts: 7.7,
fd_sal: 5000,
fd_pts: 6.4,
y_sal: 13,
y_pts: 6.4,
rating: 'None',
},
{
id: 1296,
name: 'James White',
team: 'NE',
pos: 'RB',
num: '#28',
opp: 'BUF 18th',
game_time: 'SE',
dk_sal: 4900,
dk_pts: 10,
fd_sal: 6200,
fd_pts: 8.1,
y_sal: 17,
y_pts: 8.1,
rating: 'None',
},
{
id: 1297,
name: 'Jamize Olawale',
team: 'DAL',
pos: 'RB',
num: '',
opp: 'NO 6th',
game_time: 'SN',
dk_sal: 3000,
dk_pts: 0.5,
fd_sal: 4500,
fd_pts: 0.4,
y_sal: 10,
y_pts: 0.4,
rating: 'None',
},
{
id: 1298,
name: 'Jared Cook',
team: 'NO',
pos: 'TE',
num: '#87',
opp: 'DAL 23rd',
game_time: 'SN',
dk_sal: 3600,
dk_pts: 7.5,
fd_sal: 5300,
fd_pts: 6,
y_sal: 11,
y_pts: 6,
rating: 'None',
},
{
id: 1299,
name: 'Jared Goff',
team: 'LAR',
pos: 'QB',
num: '#16',
opp: 'TB 20th',
game_time: 'SL',
dk_sal: 6300,
dk_pts: 24.6,
fd_sal: 7700,
fd_pts: 21.6,
y_sal: 28,
y_pts: 21.6,
rating: 'None',
},
{
id: 1300,
name: 'Jarius Wright',
team: 'CAR',
pos: 'WR',
num: '#13',
opp: 'HOU 24th',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 5,
fd_sal: 4500,
fd_pts: 4,
y_sal: 10,
y_pts: 4,
rating: 'None',
},
{
id: 1301,
name: 'Jaron Brown',
team: 'SEA',
pos: 'WR',
num: '#18',
opp: 'ARI 16th',
game_time: 'SL',
dk_sal: 3400,
dk_pts: 3.1,
fd_sal: 4500,
fd_pts: 2.6,
y_sal: 10,
y_pts: 2.6,
rating: 'None',
},
{
id: 1302,
name: 'Jarrett Stidham',
team: 'NE',
pos: 'QB',
num: '',
opp: 'BUF 5th',
game_time: 'SE',
dk_sal: 4100,
dk_pts: 0.6,
fd_sal: 6000,
fd_pts: 0.6,
y_sal: 20,
y_pts: 0.6,
rating: 'None',
},
{
id: 1303,
name: 'Jarvis Landry',
team: 'CLE',
pos: 'WR',
num: '#80',
opp: 'BAL 23rd',
game_time: 'SE',
dk_sal: 5100,
dk_pts: 11.6,
fd_sal: 6200,
fd_pts: 9.4,
y_sal: 15,
y_pts: 9.4,
rating: 'None',
},
{
id: 1305,
name: 'Jason Witten',
team: 'DAL',
pos: 'TE',
num: '#82',
opp: 'NO 12th',
game_time: 'SN',
dk_sal: 3900,
dk_pts: 6.7,
fd_sal: 5000,
fd_pts: 5.3,
y_sal: 13,
y_pts: 5.3,
rating: 'None',
},
{
id: 1306,
name: 'Javon Wims',
team: 'CHI',
pos: 'WR',
num: '',
opp: 'MIN 20th',
game_time: 'SL',
dk_sal: 3400,
dk_pts: 1,
fd_sal: 4500,
fd_pts: 0.8,
y_sal: 10,
y_pts: 0.8,
rating: 'None',
},
{
id: 1307,
name: 'Jaylen Samuels',
team: 'PIT',
pos: 'RB',
num: '#38',
opp: 'CIN 31st',
game_time: 'M',
dk_sal: 4200,
dk_pts: 6,
fd_sal: 5000,
fd_pts: 5,
y_sal: 23,
y_pts: 5,
rating: 'None',
},
{
id: 1309,
name: 'JD McKissic',
team: 'DET',
pos: 'RB',
num: '',
opp: 'KC 23rd',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 2.6,
fd_sal: 4600,
fd_pts: 2.2,
y_sal: 10,
y_pts: 2.2,
rating: 'None',
},
{
id: 1310,
name: 'Jeff Heuerman',
team: 'DEN',
pos: 'TE',
num: '#82',
opp: 'JAX 17th',
game_time: 'SL',
dk_sal: 2600,
dk_pts: 3.9,
fd_sal: 4500,
fd_pts: 3,
y_sal: 10,
y_pts: 3,
rating: 'None',
},
{
id: 1312,
name: 'Jerell Adams',
team: 'HOU',
pos: 'TE',
num: '',
opp: 'CAR 7th',
game_time: 'SE',
dk_sal: 2500,
dk_pts: 0.2,
fd_sal: 4000,
fd_pts: 0.2,
y_sal: 10,
y_pts: 0.2,
rating: 'None',
},
{
id: 1313,
name: 'Jeremy Sprinkle',
team: 'WAS',
pos: 'TE',
num: '#87',
opp: 'NYG 26th',
game_time: 'SE',
dk_sal: 2500,
dk_pts: 3,
fd_sal: 4000,
fd_pts: 2.4,
y_sal: 10,
y_pts: 2.4,
rating: 'None',
},
{
id: 1316,
name: 'Jesse James',
team: 'DET',
pos: 'TE',
num: '#83',
opp: 'KC 20th',
game_time: 'SE',
dk_sal: 2600,
dk_pts: 3.7,
fd_sal: 4000,
fd_pts: 2.9,
y_sal: 10,
y_pts: 2.9,
rating: 'None',
},
{
id: 1317,
name: 'Jesus Wilson',
team: 'TB',
pos: 'WR',
num: '',
opp: 'LAR 2nd',
game_time: 'SL',
dk_sal: 3000,
dk_pts: 1.3,
fd_sal: 4500,
fd_pts: 1,
y_sal: 10,
y_pts: 1,
rating: 'None',
},
{
id: 1318,
name: 'Jimmy Graham',
team: 'GB',
pos: 'TE',
num: '#80',
opp: 'PHI 13th',
game_time: 'T',
dk_sal: 3700,
dk_pts: 6.2,
fd_sal: 4600,
fd_pts: 5,
y_sal: 11,
y_pts: 5,
rating: 'None',
},
{
id: 1319,
name: 'JJ Arcega-Whiteside',
team: 'PHI',
pos: 'WR',
num: '#19',
opp: 'GB 3rd',
game_time: 'T',
dk_sal: 4000,
dk_pts: 5.9,
fd_sal: 4900,
fd_pts: 4.8,
y_sal: 10,
y_pts: 4.8,
rating: 'None',
},
{
id: 1320,
name: 'JJ Nelson',
team: 'OAK',
pos: 'WR',
num: '#15',
opp: 'IND 8th',
game_time: 'SE',
dk_sal: 3600,
dk_pts: 5.4,
fd_sal: 5200,
fd_pts: 4.5,
y_sal: 10,
y_pts: 4.5,
rating: 'None',
},
{
id: 1321,
name: 'Joe Flacco',
team: 'DEN',
pos: 'QB',
num: '#5',
opp: 'JAX 19th',
game_time: 'SL',
dk_sal: 4600,
dk_pts: 13.8,
fd_sal: 6400,
fd_pts: 13.8,
y_sal: 24,
y_pts: 13.8,
rating: 'None',
},
{
id: 1322,
name: 'Joe Mixon',
team: 'CIN',
pos: 'RB',
num: '#28',
opp: 'PIT 30th',
game_time: 'M',
dk_sal: 5800,
dk_pts: 13.8,
fd_sal: 6800,
fd_pts: 12.3,
y_sal: 20,
y_pts: 12.3,
rating: 'None',
},
{
id: 1323,
name: 'John Brown',
team: 'BUF',
pos: 'WR',
num: '#15',
opp: 'NE 1st',
game_time: 'SE',
dk_sal: 5300,
dk_pts: 10.8,
fd_sal: 5600,
fd_pts: 9.1,
y_sal: 16,
y_pts: 9.1,
rating: 'None',
},
{
id: 1325,
name: 'John Ross',
team: 'CIN',
pos: 'WR',
num: '#11',
opp: 'PIT 26th',
game_time: 'M',
dk_sal: 4900,
dk_pts: 10.4,
fd_sal: 6100,
fd_pts: 8.8,
y_sal: 19,
y_pts: 8.8,
rating: 'None',
},
{
id: 1328,
name: 'Johnny Holton',
team: 'PIT',
pos: 'WR',
num: '',
opp: 'CIN 14th',
game_time: 'M',
dk_sal: 3000,
dk_pts: 0.4,
fd_sal: 4500,
fd_pts: 0.4,
y_sal: 10,
y_pts: 0.4,
rating: 'None',
},
{
id: 1330,
name: 'Jojo Natson',
team: 'LAR',
pos: 'WR',
num: '',
opp: 'TB 12th',
game_time: 'SL',
dk_sal: 3000,
dk_pts: 1.5,
fd_sal: 4500,
fd_pts: 1.1,
y_sal: 10,
y_pts: 1.1,
rating: 'None',
},
{
id: 1333,
name: 'Jonathan Williams',
team: 'IND',
pos: 'RB',
num: '',
opp: 'OAK 20th',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 0.3,
fd_sal: 4500,
fd_pts: 0.3,
y_sal: 10,
y_pts: 0.3,
rating: 'None',
},
{
id: 1334,
name: 'Jonnu Smith',
team: 'TEN',
pos: 'TE',
num: '#81',
opp: 'ATL 16th',
game_time: 'SE',
dk_sal: 2800,
dk_pts: 3.1,
fd_sal: 4000,
fd_pts: 2.5,
y_sal: 10,
y_pts: 2.5,
rating: 'None',
},
{
id: 1335,
name: 'Jordan Akins',
team: 'HOU',
pos: 'TE',
num: '#88',
opp: 'CAR 7th',
game_time: 'SE',
dk_sal: 3100,
dk_pts: 5.2,
fd_sal: 5100,
fd_pts: 4.2,
y_sal: 12,
y_pts: 4.2,
rating: 'None',
},
{
id: 1338,
name: 'Jordan Howard',
team: 'PHI',
pos: 'RB',
num: '#24',
opp: 'GB 29th',
game_time: 'T',
dk_sal: 4600,
dk_pts: 6.1,
fd_sal: 5500,
fd_pts: 5.5,
y_sal: 16,
y_pts: 5.5,
rating: 'None',
},
{
id: 1341,
name: 'Jordan Scarlett',
team: 'CAR',
pos: 'RB',
num: '',
opp: 'HOU 10th',
game_time: 'SE',
dk_sal: 3100,
dk_pts: 0.6,
fd_sal: 4500,
fd_pts: 0.5,
y_sal: 10,
y_pts: 0.5,
rating: 'None',
},
{
id: 1342,
name: 'Jordan Wilkins',
team: 'IND',
pos: 'RB',
num: '#20',
opp: 'OAK 20th',
game_time: 'SE',
dk_sal: 3200,
dk_pts: 3.6,
fd_sal: 4900,
fd_pts: 3.1,
y_sal: 10,
y_pts: 3.1,
rating: 'None',
},
{
id: 1343,
name: 'Josh Allen',
team: 'BUF',
pos: 'QB',
num: '#17',
opp: 'NE 1st',
game_time: 'SE',
dk_sal: 5600,
dk_pts: 17.8,
fd_sal: 7400,
fd_pts: 17.7,
y_sal: 25,
y_pts: 17.7,
rating: 'None',
},
{
id: 1344,
name: 'Josh Gordon',
team: 'NE',
pos: 'WR',
num: '#10',
opp: 'BUF 4th',
game_time: 'SE',
dk_sal: 5900,
dk_pts: 12.8,
fd_sal: 6200,
fd_pts: 10.7,
y_sal: 19,
y_pts: 10.7,
rating: 'None',
},
{
id: 1345,
name: 'Josh Hill',
team: 'NO',
pos: 'TE',
num: '',
opp: 'DAL 23rd',
game_time: 'SN',
dk_sal: 2600,
dk_pts: 2.9,
fd_sal: 4000,
fd_pts: 2.2,
y_sal: 10,
y_pts: 2.2,
rating: 'None',
},
{
id: 1346,
name: 'Josh Jacobs',
team: 'OAK',
pos: 'RB',
num: '#28',
opp: 'IND 28th',
game_time: 'SE',
dk_sal: 5100,
dk_pts: 13.4,
fd_sal: 6000,
fd_pts: 12.4,
y_sal: 25,
y_pts: 12.4,
rating: 'None',
},
{
id: 1348,
name: 'Josh McCown',
team: 'PHI',
pos: 'QB',
num: '',
opp: 'GB 2nd',
game_time: 'T',
dk_sal: 4600,
dk_pts: 0.7,
fd_sal: 6000,
fd_pts: 0.7,
y_sal: 20,
y_pts: 0.7,
rating: 'None',
},
{
id: 1350,
name: 'Josh Reynolds',
team: 'LAR',
pos: 'WR',
num: '#83',
opp: 'TB 12th',
game_time: 'SL',
dk_sal: 3100,
dk_pts: 4.8,
fd_sal: 4500,
fd_pts: 4,
y_sal: 10,
y_pts: 4,
rating: 'None',
},
{
id: 1351,
name: 'Josh Rosen',
team: 'MIA',
pos: 'QB',
num: '#3',
opp: 'LAC 21st',
game_time: 'SE',
dk_sal: 4500,
dk_pts: 11.9,
fd_sal: 6200,
fd_pts: 11.9,
y_sal: 21,
y_pts: 11.9,
rating: 'None',
},
{
id: 1352,
name: 'Joshua Dobbs',
team: 'JAX',
pos: 'QB',
num: '',
opp: 'DEN 4th',
game_time: 'SL',
dk_sal: 4100,
dk_pts: 0.2,
fd_sal: 6000,
fd_pts: 0.2,
y_sal: 20,
y_pts: 0.2,
rating: 'None',
},
{
id: 1354,
name: 'JuJu Smith-Schuster',
team: 'PIT',
pos: 'WR',
num: '#19',
opp: 'CIN 14th',
game_time: 'M',
dk_sal: 6600,
dk_pts: 17.3,
fd_sal: 7500,
fd_pts: 14.3,
y_sal: 24,
y_pts: 14.3,
rating: 'None',
},
{
id: 1355,
name: 'Julian Edelman',
team: 'NE',
pos: 'WR',
num: '#11',
opp: 'BUF 4th',
game_time: 'SE',
dk_sal: 6500,
dk_pts: 15,
fd_sal: 6900,
fd_pts: 12,
y_sal: 18,
y_pts: 12,
rating: 'None',
},
{
id: 1356,
name: 'Julio Jones',
team: 'ATL',
pos: 'WR',
num: '#11',
opp: 'TEN 10th',
game_time: 'SE',
dk_sal: 7800,
dk_pts: 19.8,
fd_sal: 8500,
fd_pts: 16.5,
y_sal: 38,
y_pts: 16.5,
rating: 'None',
},
{
id: 1357,
name: 'Justice Hill',
team: 'BAL',
pos: 'RB',
num: '#43',
opp: 'CLE 14th',
game_time: 'SE',
dk_sal: 3500,
dk_pts: 3.5,
fd_sal: 4500,
fd_pts: 2.9,
y_sal: 12,
y_pts: 2.9,
rating: 'None',
},
{
id: 1358,
name: 'Justin Hardy',
team: 'ATL',
pos: 'WR',
num: '#14',
opp: 'TEN 10th',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 3.6,
fd_sal: 4500,
fd_pts: 2.8,
y_sal: 10,
y_pts: 2.8,
rating: 'None',
},
{
id: 1359,
name: 'Justin Jackson',
team: 'LAC',
pos: 'RB',
num: '#22',
opp: 'MIA 32nd',
game_time: 'SE',
dk_sal: 4100,
dk_pts: 9.2,
fd_sal: 6200,
fd_pts: 8.4,
y_sal: 18,
y_pts: 8.4,
rating: 'None',
},
{
id: 1360,
name: 'Justin Watson',
team: 'TB',
pos: 'WR',
num: '',
opp: 'LAR 2nd',
game_time: 'SL',
dk_sal: 3000,
dk_pts: 0.5,
fd_sal: 4500,
fd_pts: 0.4,
y_sal: 10,
y_pts: 0.4,
rating: 'None',
},
{
id: 1361,
name: 'Juwann Winfree',
team: 'DEN',
pos: 'WR',
num: '',
opp: 'JAX 19th',
game_time: 'SL',
dk_sal: 3000,
dk_pts: 1.4,
fd_sal: 4500,
fd_pts: 1.3,
y_sal: 10,
y_pts: 1.3,
rating: 'None',
},
{
id: 1363,
name: 'Kalen Ballage',
team: 'MIA',
pos: 'RB',
num: '#27',
opp: 'LAC 21st',
game_time: 'SE',
dk_sal: 3800,
dk_pts: 5.6,
fd_sal: 4900,
fd_pts: 4.8,
y_sal: 10,
y_pts: 4.8,
rating: 'None',
},
{
id: 1364,
name: 'Chiefs',
team: 'KC',
pos: 'DST',
num: '',
opp: 'DET 12th',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 6.6,
fd_sal: 3500,
fd_pts: 6.6,
y_sal: 16,
y_pts: 6.6,
rating: 'None',
},
{
id: 1367,
name: 'Keelan Cole',
team: 'JAX',
pos: 'WR',
num: '#84',
opp: 'DEN 5th',
game_time: 'SL',
dk_sal: 3500,
dk_pts: 0.8,
fd_sal: 4500,
fd_pts: 0.7,
y_sal: 10,
y_pts: 0.7,
rating: 'None',
},
{
id: 1369,
name: 'Keenan Allen',
team: 'LAC',
pos: 'WR',
num: '#13',
opp: 'MIA 28th',
game_time: 'SE',
dk_sal: 7600,
dk_pts: 25.2,
fd_sal: 8100,
fd_pts: 18.4,
y_sal: 33,
y_pts: 18.4,
rating: 'None',
},
{
id: 1370,
name: 'Keesean Johnson',
team: 'ARI',
pos: 'WR',
num: '#19',
opp: 'SEA 9th',
game_time: 'SL',
dk_sal: 3200,
dk_pts: 5.9,
fd_sal: 4600,
fd_pts: 4.7,
y_sal: 10,
y_pts: 4.7,
rating: 'None',
},
{
id: 1372,
name: 'Keke Coutee',
team: 'HOU',
pos: 'WR',
num: '#16',
opp: 'CAR 7th',
game_time: 'SE',
dk_sal: 3100,
dk_pts: 5.2,
fd_sal: 4500,
fd_pts: 4.2,
y_sal: 11,
y_pts: 4.2,
rating: 'None',
},
{
id: 1375,
name: 'Kenjon Barner',
team: 'ATL',
pos: 'RB',
num: '#38',
opp: 'TEN 8th',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 0.1,
fd_sal: 4500,
fd_pts: 0.1,
y_sal: 10,
y_pts: 0.1,
rating: 'None',
},
{
id: 1376,
name: 'Kenny Golladay',
team: 'DET',
pos: 'WR',
num: '#19',
opp: 'KC 18th',
game_time: 'SE',
dk_sal: 5900,
dk_pts: 14.9,
fd_sal: 6700,
fd_pts: 12.5,
y_sal: 20,
y_pts: 12.5,
rating: 'None',
},
{
id: 1377,
name: 'Kenny Stills',
team: 'HOU',
pos: 'WR',
num: '#10',
opp: 'CAR 7th',
game_time: 'SE',
dk_sal: 4200,
dk_pts: 8.5,
fd_sal: 5400,
fd_pts: 7.1,
y_sal: 15,
y_pts: 7.1,
rating: 'None',
},
{
id: 1378,
name: 'Kenyan Drake',
team: 'MIA',
pos: 'RB',
num: '#32',
opp: 'LAC 21st',
game_time: 'SE',
dk_sal: 4200,
dk_pts: 11.3,
fd_sal: 5300,
fd_pts: 9.6,
y_sal: 14,
y_pts: 9.6,
rating: 'None',
},
{
id: 1380,
name: 'Kerryon Johnson',
team: 'DET',
pos: 'RB',
num: '#33',
opp: 'KC 23rd',
game_time: 'SE',
dk_sal: 5400,
dk_pts: 14.7,
fd_sal: 6500,
fd_pts: 13.3,
y_sal: 19,
y_pts: 13.3,
rating: 'None',
},
{
id: 1386,
name: 'Kirk Cousins',
team: 'MIN',
pos: 'QB',
num: '#8',
opp: 'CHI 7th',
game_time: 'SL',
dk_sal: 5000,
dk_pts: 14.3,
fd_sal: 6900,
fd_pts: 14.3,
y_sal: 24,
y_pts: 14.3,
rating: 'None',
},
{
id: 1387,
name: 'Kyle Allen',
team: 'CAR',
pos: 'QB',
num: '#7',
opp: 'HOU 27th',
game_time: 'SE',
dk_sal: 5200,
dk_pts: 16.8,
fd_sal: 6800,
fd_pts: 16.7,
y_sal: 27,
y_pts: 16.7,
rating: 'None',
},
{
id: 1389,
name: 'Kyle Rudolph',
team: 'MIN',
pos: 'TE',
num: '#82',
opp: 'CHI 21st',
game_time: 'SL',
dk_sal: 3500,
dk_pts: 5.9,
fd_sal: 4600,
fd_pts: 4.7,
y_sal: 10,
y_pts: 4.7,
rating: 'None',
},
{
id: 1391,
name: 'Kyler Murray',
team: 'ARI',
pos: 'QB',
num: '#1',
opp: 'SEA 14th',
game_time: 'SL',
dk_sal: 6000,
dk_pts: 19.3,
fd_sal: 7600,
fd_pts: 19.2,
y_sal: 28,
y_pts: 19.2,
rating: 'None',
},
{
id: 1392,
name: 'Lamar Jackson',
team: 'BAL',
pos: 'QB',
num: '#8',
opp: 'CLE 11th',
game_time: 'SE',
dk_sal: 6900,
dk_pts: 22.8,
fd_sal: 8300,
fd_pts: 22.7,
y_sal: 37,
y_pts: 22.7,
rating: 'None',
},
{
id: 1393,
name: 'Larry Fitzgerald',
team: 'ARI',
pos: 'WR',
num: '#11',
opp: 'SEA 9th',
game_time: 'SL',
dk_sal: 5600,
dk_pts: 14.4,
fd_sal: 5900,
fd_pts: 11.6,
y_sal: 22,
y_pts: 11.6,
rating: 'None',
},
{
id: 1394,
name: 'Latavius Murray',
team: 'NO',
pos: 'RB',
num: '#28',
opp: 'DAL 12th',
game_time: 'SN',
dk_sal: 3900,
dk_pts: 5.4,
fd_sal: 5200,
fd_pts: 4.8,
y_sal: 13,
y_pts: 4.8,
rating: 'None',
},
{
id: 1395,
name: 'Lee Smith',
team: 'BUF',
pos: 'TE',
num: '#85',
opp: 'NE 4th',
game_time: 'SE',
dk_sal: 2500,
dk_pts: 0.7,
fd_sal: 4000,
fd_pts: 0.6,
y_sal: 10,
y_pts: 0.6,
rating: 'None',
},
{
id: 1396,
name: 'Leonard Fournette',
team: 'JAX',
pos: 'RB',
num: '#27',
opp: 'DEN 27th',
game_time: 'SL',
dk_sal: 6000,
dk_pts: 17.1,
fd_sal: 6400,
fd_pts: 15.1,
y_sal: 20,
y_pts: 15.1,
rating: 'None',
},
{
id: 1397,
name: 'LeSean McCoy',
team: 'KC',
pos: 'RB',
num: '#25',
opp: 'DET 26th',
game_time: 'SE',
dk_sal: 5600,
dk_pts: 11.4,
fd_sal: 6300,
fd_pts: 10.2,
y_sal: 18,
y_pts: 10.2,
rating: 'None',
},
{
id: 1399,
name: 'Logan Paulsen',
team: 'HOU',
pos: 'TE',
num: '',
opp: 'CAR 7th',
game_time: 'SE',
dk_sal: 2500,
dk_pts: 0.6,
fd_sal: 4000,
fd_pts: 0.5,
y_sal: 10,
y_pts: 0.5,
rating: 'None',
},
{
id: 1400,
name: 'Logan Thomas',
team: 'DET',
pos: 'TE',
num: '',
opp: 'KC 20th',
game_time: 'SE',
dk_sal: 2500,
dk_pts: 1.8,
fd_sal: 4000,
fd_pts: 1.4,
y_sal: 10,
y_pts: 1.4,
rating: 'None',
},
{
id: 1402,
name: 'Chargers',
team: 'LAC',
pos: 'DST',
num: '',
opp: 'MIA 31st',
game_time: 'SE',
dk_sal: 3800,
dk_pts: 12.1,
fd_sal: 5300,
fd_pts: 12.1,
y_sal: 21,
y_pts: 12.1,
rating: 'None',
},
{
id: 1403,
name: 'Rams',
team: 'LAR',
pos: 'DST',
num: '',
opp: 'TB 14th',
game_time: 'SL',
dk_sal: 3500,
dk_pts: 8.9,
fd_sal: 4300,
fd_pts: 8.9,
y_sal: 20,
y_pts: 8.9,
rating: 'None',
},
{
id: 1404,
name: 'Luke Stocker',
team: 'ATL',
pos: 'TE',
num: '',
opp: 'TEN 27th',
game_time: 'SE',
dk_sal: 2500,
dk_pts: 2.6,
fd_sal: 4000,
fd_pts: 2.1,
y_sal: 10,
y_pts: 2.1,
rating: 'None',
},
{
id: 1405,
name: 'Mack Hollins',
team: 'PHI',
pos: 'WR',
num: '#16',
opp: 'GB 3rd',
game_time: 'T',
dk_sal: 3500,
dk_pts: 7,
fd_sal: 5000,
fd_pts: 5.7,
y_sal: 12,
y_pts: 5.7,
rating: 'None',
},
{
id: 1406,
name: 'Malcolm Brown',
team: 'LAR',
pos: 'RB',
num: '#34',
opp: 'TB 2nd',
game_time: 'SL',
dk_sal: 4400,
dk_pts: 6.3,
fd_sal: 5500,
fd_pts: 5.8,
y_sal: 13,
y_pts: 5.8,
rating: 'None',
},
{
id: 1410,
name: 'Marcedes Lewis',
team: 'GB',
pos: 'TE',
num: '#89',
opp: 'PHI 13th',
game_time: 'T',
dk_sal: 2500,
dk_pts: 3.4,
fd_sal: 4000,
fd_pts: 2.8,
y_sal: 10,
y_pts: 2.8,
rating: 'None',
},
{
id: 1413,
name: 'Marcus Mariota',
team: 'TEN',
pos: 'QB',
num: '#8',
opp: 'ATL 16th',
game_time: 'SE',
dk_sal: 5100,
dk_pts: 17.3,
fd_sal: 6900,
fd_pts: 17.3,
y_sal: 24,
y_pts: 17.3,
rating: 'None',
},
{
id: 1415,
name: 'Mark Andrews',
team: 'BAL',
pos: 'TE',
num: '#89',
opp: 'CLE 15th',
game_time: 'SE',
dk_sal: 5000,
dk_pts: 11.6,
fd_sal: 6100,
fd_pts: 9.6,
y_sal: 24,
y_pts: 9.6,
rating: 'None',
},
{
id: 1416,
name: 'Mark Ingram',
team: 'BAL',
pos: 'RB',
num: '#21',
opp: 'CLE 14th',
game_time: 'SE',
dk_sal: 6600,
dk_pts: 14.2,
fd_sal: 7800,
fd_pts: 13.1,
y_sal: 24,
y_pts: 13.1,
rating: 'None',
},
{
id: 1418,
name: 'Mark Walton',
team: 'MIA',
pos: 'RB',
num: '',
opp: 'LAC 21st',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 1.3,
fd_sal: 4500,
fd_pts: 1.1,
y_sal: 10,
y_pts: 1.1,
rating: 'None',
},
{
id: 1419,
name: 'Marlon Mack',
team: 'IND',
pos: 'RB',
num: '#25',
opp: 'OAK 20th',
game_time: 'SE',
dk_sal: 6100,
dk_pts: 14.5,
fd_sal: 7300,
fd_pts: 13.7,
y_sal: 24,
y_pts: 13.7,
rating: 'None',
},
{
id: 1420,
name: 'Marqise Lee',
team: 'JAX',
pos: 'WR',
num: '#11',
opp: 'DEN 5th',
game_time: 'SL',
dk_sal: 4300,
dk_pts: 4.2,
fd_sal: 4500,
fd_pts: 3.4,
y_sal: 11,
y_pts: 3.4,
rating: 'None',
},
{
id: 1421,
name: 'Marquez Valdes-Scantling',
team: 'GB',
pos: 'WR',
num: '#83',
opp: 'PHI 29th',
game_time: 'T',
dk_sal: 4800,
dk_pts: 11.7,
fd_sal: 6000,
fd_pts: 9.7,
y_sal: 17,
y_pts: 9.7,
rating: 'None',
},
{
id: 1422,
name: 'Marquise Brown',
team: 'BAL',
pos: 'WR',
num: '#15',
opp: 'CLE 11th',
game_time: 'SE',
dk_sal: 5800,
dk_pts: 13.7,
fd_sal: 5700,
fd_pts: 11.6,
y_sal: 20,
y_pts: 11.6,
rating: 'None',
},
{
id: 1423,
name: 'Marvin Jones',
team: 'DET',
pos: 'WR',
num: '#11',
opp: 'KC 18th',
game_time: 'SE',
dk_sal: 5400,
dk_pts: 12.5,
fd_sal: 5900,
fd_pts: 10.4,
y_sal: 17,
y_pts: 10.4,
rating: 'None',
},
{
id: 1424,
name: 'Mason Rudolph',
team: 'PIT',
pos: 'QB',
num: '#2',
opp: 'CIN 22nd',
game_time: 'M',
dk_sal: 5300,
dk_pts: 17.2,
fd_sal: 6500,
fd_pts: 17.2,
y_sal: 27,
y_pts: 17.2,
rating: 'None',
},
{
id: 1426,
name: 'Matt Barkley',
team: 'BUF',
pos: 'QB',
num: '#5',
opp: 'NE 1st',
game_time: 'SE',
dk_sal: 4100,
dk_pts: 0.1,
fd_sal: 6000,
fd_pts: 0.1,
y_sal: 20,
y_pts: 0.1,
rating: 'None',
},
{
id: 1427,
name: 'Matt LaCosse',
team: 'NE',
pos: 'TE',
num: '#83',
opp: 'BUF 3rd',
game_time: 'SE',
dk_sal: 2700,
dk_pts: 4.4,
fd_sal: 4000,
fd_pts: 3.5,
y_sal: 10,
y_pts: 3.5,
rating: 'None',
},
{
id: 1428,
name: 'Matt Moore',
team: 'KC',
pos: 'QB',
num: '',
opp: 'DET 17th',
game_time: 'SE',
dk_sal: 4100,
dk_pts: 0.7,
fd_sal: 6000,
fd_pts: 0.7,
y_sal: 20,
y_pts: 0.7,
rating: 'None',
},
{
id: 1429,
name: 'Matt Ryan',
team: 'ATL',
pos: 'QB',
num: '#2',
opp: 'TEN 10th',
game_time: 'SE',
dk_sal: 5900,
dk_pts: 19.6,
fd_sal: 7900,
fd_pts: 19.6,
y_sal: 35,
y_pts: 19.6,
rating: 'None',
},
{
id: 1430,
name: 'Matt Schaub',
team: 'ATL',
pos: 'QB',
num: '#8',
opp: 'TEN 10th',
game_time: 'SE',
dk_sal: 4100,
dk_pts: 0.6,
fd_sal: 6000,
fd_pts: 0.6,
y_sal: 20,
y_pts: 0.6,
rating: 'None',
},
{
id: 1433,
name: 'Matthew Stafford',
team: 'DET',
pos: 'QB',
num: '#9',
opp: 'KC 18th',
game_time: 'SE',
dk_sal: 5500,
dk_pts: 18.6,
fd_sal: 6900,
fd_pts: 18.6,
y_sal: 29,
y_pts: 18.6,
rating: 'None',
},
{
id: 1434,
name: 'Maxx Williams',
team: 'ARI',
pos: 'TE',
num: '#87',
opp: 'SEA 24th',
game_time: 'SL',
dk_sal: 2500,
dk_pts: 2.3,
fd_sal: 4000,
fd_pts: 1.9,
y_sal: 12,
y_pts: 1.9,
rating: 'None',
},
{
id: 1435,
name: 'Mecole Hardman',
team: 'KC',
pos: 'WR',
num: '#17',
opp: 'DET 22nd',
game_time: 'SE',
dk_sal: 5100,
dk_pts: 11.2,
fd_sal: 6500,
fd_pts: 9.5,
y_sal: 16,
y_pts: 9.5,
rating: 'None',
},
{
id: 1437,
name: 'Dolphins',
team: 'MIA',
pos: 'DST',
num: '',
opp: 'LAC 5th',
game_time: 'SE',
dk_sal: 2000,
dk_pts: 4.3,
fd_sal: 2500,
fd_pts: 4.3,
y_sal: 5,
y_pts: 4.3,
rating: 'None',
},
{
id: 1440,
name: 'Michael Thomas',
team: 'NO',
pos: 'WR',
num: '#13',
opp: 'DAL 6th',
game_time: 'SN',
dk_sal: 7000,
dk_pts: 18,
fd_sal: 7700,
fd_pts: 14.5,
y_sal: 30,
y_pts: 14.5,
rating: 'None',
},
{
id: 1442,
name: 'Mike Boone',
team: 'MIN',
pos: 'RB',
num: '',
opp: 'CHI 4th',
game_time: 'SL',
dk_sal: 3000,
dk_pts: 0.7,
fd_sal: 4500,
fd_pts: 0.6,
y_sal: 10,
y_pts: 0.6,
rating: 'None',
},
{
id: 1443,
name: 'Mike Davis',
team: 'CHI',
pos: 'RB',
num: '#25',
opp: 'MIN 9th',
game_time: 'SL',
dk_sal: 3300,
dk_pts: 4.8,
fd_sal: 4900,
fd_pts: 4.1,
y_sal: 11,
y_pts: 4.1,
rating: 'None',
},
{
id: 1444,
name: 'Mike Evans',
team: 'TB',
pos: 'WR',
num: '#13',
opp: 'LAR 2nd',
game_time: 'SL',
dk_sal: 7100,
dk_pts: 17,
fd_sal: 7700,
fd_pts: 14.3,
y_sal: 23,
y_pts: 14.3,
rating: 'None',
},
{
id: 1445,
name: 'Mike Gesicki',
team: 'MIA',
pos: 'TE',
num: '#88',
opp: 'LAC 31st',
game_time: 'SE',
dk_sal: 2600,
dk_pts: 4.2,
fd_sal: 4600,
fd_pts: 3.2,
y_sal: 10,
y_pts: 3.2,
rating: 'None',
},
{
id: 1446,
name: 'Mike Glennon',
team: 'OAK',
pos: 'QB',
num: '',
opp: 'IND 26th',
game_time: 'SE',
dk_sal: 4100,
dk_pts: 0.5,
fd_sal: 6000,
fd_pts: 0.4,
y_sal: 20,
y_pts: 0.4,
rating: 'None',
},
{
id: 1447,
name: 'Mike Thomas',
team: 'LAR',
pos: 'WR',
num: '',
opp: 'TB 12th',
game_time: 'SL',
dk_sal: 3000,
dk_pts: 0.1,
fd_sal: 4500,
fd_pts: 0.1,
y_sal: 10,
y_pts: 0.1,
rating: 'None',
},
{
id: 1449,
name: 'Mike Williams',
team: 'LAC',
pos: 'WR',
num: '#81',
opp: 'MIA 28th',
game_time: 'SE',
dk_sal: 4800,
dk_pts: 13.1,
fd_sal: 6100,
fd_pts: 11.1,
y_sal: 21,
y_pts: 11.1,
rating: 'None',
},
{
id: 1450,
name: 'Miles Boykin',
team: 'BAL',
pos: 'WR',
num: '#80',
opp: 'CLE 11th',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 3.6,
fd_sal: 4500,
fd_pts: 2.9,
y_sal: 10,
y_pts: 2.9,
rating: 'None',
},
{
id: 1451,
name: 'Miles Sanders',
team: 'PHI',
pos: 'RB',
num: '#26',
opp: 'GB 29th',
game_time: 'T',
dk_sal: 4500,
dk_pts: 9.7,
fd_sal: 5700,
fd_pts: 8.7,
y_sal: 14,
y_pts: 8.7,
rating: 'None',
},
{
id: 1452,
name: 'Vikings',
team: 'MIN',
pos: 'DST',
num: '',
opp: 'CHI 29th',
game_time: 'SL',
dk_sal: 3400,
dk_pts: 6.8,
fd_sal: 4000,
fd_pts: 6.8,
y_sal: 19,
y_pts: 6.8,
rating: 'None',
},
{
id: 1453,
name: 'Mitch Trubisky',
team: 'CHI',
pos: 'QB',
num: '#10',
opp: 'MIN 12th',
game_time: 'SL',
dk_sal: 5000,
dk_pts: 16.6,
fd_sal: 6800,
fd_pts: 16.6,
y_sal: 20,
y_pts: 16.6,
rating: 'None',
},
{
id: 1454,
name: 'Mo Alie-Cox',
team: 'IND',
pos: 'TE',
num: '#81',
opp: 'OAK 24th',
game_time: 'SE',
dk_sal: 2500,
dk_pts: 2.8,
fd_sal: 4200,
fd_pts: 2.3,
y_sal: 10,
y_pts: 2.3,
rating: 'None',
},
{
id: 1455,
name: 'Mohamed Sanu',
team: 'ATL',
pos: 'WR',
num: '#12',
opp: 'TEN 10th',
game_time: 'SE',
dk_sal: 4100,
dk_pts: 10.8,
fd_sal: 5700,
fd_pts: 8.8,
y_sal: 12,
y_pts: 8.8,
rating: 'None',
},
{
id: 1457,
name: 'MyCole Pruitt',
team: 'TEN',
pos: 'TE',
num: '',
opp: 'ATL 16th',
game_time: 'SE',
dk_sal: 2500,
dk_pts: 0.5,
fd_sal: 4000,
fd_pts: 0.4,
y_sal: 10,
y_pts: 0.4,
rating: 'None',
},
{
id: 1458,
name: 'Myles Gaskin',
team: 'MIA',
pos: 'RB',
num: '',
opp: 'LAC 21st',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 0.4,
fd_sal: 4500,
fd_pts: 0.4,
y_sal: 10,
y_pts: 0.4,
rating: 'None',
},
{
id: 1461,
name: 'Nelson Agholor',
team: 'PHI',
pos: 'WR',
num: '#13',
opp: 'GB 3rd',
game_time: 'T',
dk_sal: 5500,
dk_pts: 10.6,
fd_sal: 6200,
fd_pts: 8.6,
y_sal: 16,
y_pts: 8.6,
rating: 'None',
},
{
id: 1462,
name: 'Patriots',
team: 'NE',
pos: 'DST',
num: '',
opp: 'BUF 8th',
game_time: 'SE',
dk_sal: 3700,
dk_pts: 8.3,
fd_sal: 4800,
fd_pts: 8.3,
y_sal: 21,
y_pts: 8.3,
rating: 'None',
},
{
id: 1463,
name: 'Saints',
team: 'NO',
pos: 'DST',
num: '',
opp: 'DAL 3rd',
game_time: 'SN',
dk_sal: 2500,
dk_pts: 6.9,
fd_sal: 3600,
fd_pts: 6.9,
y_sal: 11,
y_pts: 6.9,
rating: 'None',
},
{
id: 1464,
name: 'Giants',
team: 'NYG',
pos: 'DST',
num: '',
opp: 'WAS 22nd',
game_time: 'SE',
dk_sal: 3600,
dk_pts: 6.3,
fd_sal: 3800,
fd_pts: 6.3,
y_sal: 10,
y_pts: 6.3,
rating: 'None',
},
{
id: 1465,
name: 'Nick Bawden',
team: 'DET',
pos: 'RB',
num: '',
opp: 'KC 23rd',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 0.8,
fd_sal: 4500,
fd_pts: 0.6,
y_sal: 10,
y_pts: 0.6,
rating: 'None',
},
{
id: 1466,
name: 'Nick Bellore',
team: 'SEA',
pos: 'RB',
num: '',
opp: 'ARI 14th',
game_time: 'SL',
dk_sal: 3000,
dk_pts: 0.3,
fd_sal: 4500,
fd_pts: 0.2,
y_sal: 10,
y_pts: 0.2,
rating: 'None',
},
{
id: 1467,
name: 'Nick Boyle',
team: 'BAL',
pos: 'TE',
num: '#86',
opp: 'CLE 15th',
game_time: 'SE',
dk_sal: 2900,
dk_pts: 3.9,
fd_sal: 4900,
fd_pts: 3.1,
y_sal: 10,
y_pts: 3.1,
rating: 'None',
},
{
id: 1468,
name: 'Nick Chubb',
team: 'CLE',
pos: 'RB',
num: '#24',
opp: 'BAL 7th',
game_time: 'SE',
dk_sal: 6400,
dk_pts: 15.5,
fd_sal: 7300,
fd_pts: 14.1,
y_sal: 23,
y_pts: 14.1,
rating: 'None',
},
{
id: 1471,
name: 'Nick OLeary',
team: 'MIA',
pos: 'TE',
num: '#83',
opp: 'LAC 31st',
game_time: 'SE',
dk_sal: 2500,
dk_pts: 1.4,
fd_sal: 4200,
fd_pts: 1,
y_sal: 10,
y_pts: 1,
rating: 'None',
},
{
id: 1472,
name: 'Nick Vannett',
team: 'SEA',
pos: 'TE',
num: '#81',
opp: 'ARI 32nd',
game_time: 'SL',
dk_sal: 2700,
dk_pts: 3,
fd_sal: 4500,
fd_pts: 2.4,
y_sal: 10,
y_pts: 2.4,
rating: 'None',
},
{
id: 1473,
name: 'Noah Fant',
team: 'DEN',
pos: 'TE',
num: '#87',
opp: 'JAX 17th',
game_time: 'SL',
dk_sal: 2600,
dk_pts: 6.8,
fd_sal: 4600,
fd_pts: 5.5,
y_sal: 11,
y_pts: 5.5,
rating: 'None',
},
{
id: 1475,
name: 'Nyheim Hines',
team: 'IND',
pos: 'RB',
num: '#21',
opp: 'OAK 20th',
game_time: 'SE',
dk_sal: 4000,
dk_pts: 6.7,
fd_sal: 4800,
fd_pts: 5.4,
y_sal: 11,
y_pts: 5.4,
rating: 'None',
},
{
id: 1476,
name: 'Raiders',
team: 'OAK',
pos: 'DST',
num: '',
opp: 'IND 19th',
game_time: 'SE',
dk_sal: 2700,
dk_pts: 4.7,
fd_sal: 3400,
fd_pts: 4.7,
y_sal: 10,
y_pts: 4.7,
rating: 'None',
},
{
id: 1477,
name: 'Odell Beckham Jr',
team: 'CLE',
pos: 'WR',
num: '#13',
opp: 'BAL 23rd',
game_time: 'SE',
dk_sal: 7300,
dk_pts: 16.8,
fd_sal: 7900,
fd_pts: 14.1,
y_sal: 35,
y_pts: 14.1,
rating: 'None',
},
{
id: 1478,
name: 'OJ Howard',
team: 'TB',
pos: 'TE',
num: '#80',
opp: 'LAR 9th',
game_time: 'SL',
dk_sal: 3900,
dk_pts: 8.9,
fd_sal: 5900,
fd_pts: 7.4,
y_sal: 16,
y_pts: 7.4,
rating: 'None',
},
{
id: 1479,
name: 'Olabisi Johnson',
team: 'MIN',
pos: 'WR',
num: '',
opp: 'CHI 15th',
game_time: 'SL',
dk_sal: 3000,
dk_pts: 1.2,
fd_sal: 4500,
fd_pts: 1,
y_sal: 10,
y_pts: 1,
rating: 'None',
},
{
id: 1483,
name: 'Parris Campbell',
team: 'IND',
pos: 'WR',
num: '#15',
opp: 'OAK 27th',
game_time: 'SE',
dk_sal: 4900,
dk_pts: 6.2,
fd_sal: 5600,
fd_pts: 5.2,
y_sal: 12,
y_pts: 5.2,
rating: 'None',
},
{
id: 1484,
name: 'Patrick Laird',
team: 'MIA',
pos: 'RB',
num: '',
opp: 'LAC 21st',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 0.5,
fd_sal: 4500,
fd_pts: 0.4,
y_sal: 10,
y_pts: 0.4,
rating: 'None',
},
{
id: 1485,
name: 'Patrick Mahomes',
team: 'KC',
pos: 'QB',
num: '#15',
opp: 'DET 17th',
game_time: 'SE',
dk_sal: 7500,
dk_pts: 28.2,
fd_sal: 9200,
fd_pts: 25.2,
y_sal: 40,
y_pts: 25.2,
rating: 'None',
},
{
id: 1486,
name: 'Paul Perkins',
team: 'DET',
pos: 'RB',
num: '',
opp: 'KC 23rd',
game_time: 'SE',
dk_sal: 3100,
dk_pts: 0.1,
fd_sal: 4500,
fd_pts: 0.1,
y_sal: 10,
y_pts: 0.1,
rating: 'None',
},
{
id: 1487,
name: 'Paul Richardson',
team: 'WAS',
pos: 'WR',
num: '#10',
opp: 'NYG 32nd',
game_time: 'SE',
dk_sal: 3700,
dk_pts: 9.7,
fd_sal: 4700,
fd_pts: 8,
y_sal: 15,
y_pts: 8,
rating: 'None',
},
{
id: 1488,
name: 'Peyton Barber',
team: 'TB',
pos: 'RB',
num: '#25',
opp: 'LAR 23rd',
game_time: 'SL',
dk_sal: 4300,
dk_pts: 10,
fd_sal: 5700,
fd_pts: 9.1,
y_sal: 15,
y_pts: 9.1,
rating: 'None',
},
{
id: 1489,
name: 'Pharaoh Brown',
team: 'CLE',
pos: 'TE',
num: '',
opp: 'BAL 10th',
game_time: 'SE',
dk_sal: 2500,
dk_pts: 0.5,
fd_sal: 4000,
fd_pts: 0.4,
y_sal: 10,
y_pts: 0.4,
rating: 'None',
},
{
id: 1491,
name: 'Eagles',
team: 'PHI',
pos: 'DST',
num: '',
opp: 'GB 28th',
game_time: 'T',
dk_sal: 2900,
dk_pts: 5.5,
fd_sal: 3300,
fd_pts: 5.5,
y_sal: 12,
y_pts: 5.5,
rating: 'None',
},
{
id: 1492,
name: 'Philip Rivers',
team: 'LAC',
pos: 'QB',
num: '#17',
opp: 'MIA 31st',
game_time: 'SE',
dk_sal: 6200,
dk_pts: 24.4,
fd_sal: 7800,
fd_pts: 21.5,
y_sal: 38,
y_pts: 21.5,
rating: 'None',
},
{
id: 1493,
name: 'Phillip Dorsett',
team: 'NE',
pos: 'WR',
num: '#13',
opp: 'BUF 4th',
game_time: 'SE',
dk_sal: 4700,
dk_pts: 9.8,
fd_sal: 5900,
fd_pts: 8.2,
y_sal: 13,
y_pts: 8.2,
rating: 'None',
},
{
id: 1494,
name: 'Phillip Lindsay',
team: 'DEN',
pos: 'RB',
num: '#30',
opp: 'JAX 13th',
game_time: 'SL',
dk_sal: 5200,
dk_pts: 14.8,
fd_sal: 6900,
fd_pts: 13.2,
y_sal: 18,
y_pts: 13.2,
rating: 'None',
},
{
id: 1496,
name: 'Steelers',
team: 'PIT',
pos: 'DST',
num: '',
opp: 'CIN 18th',
game_time: 'M',
dk_sal: 3200,
dk_pts: 8.8,
fd_sal: 4200,
fd_pts: 8.8,
y_sal: 12,
y_pts: 8.8,
rating: 'None',
},
{
id: 1497,
name: 'Preston Williams',
team: 'MIA',
pos: 'WR',
num: '#18',
opp: 'LAC 16th',
game_time: 'SE',
dk_sal: 3900,
dk_pts: 8.5,
fd_sal: 5200,
fd_pts: 7,
y_sal: 14,
y_pts: 7,
rating: 'None',
},
{
id: 1498,
name: 'Qadree Ollison',
team: 'ATL',
pos: 'RB',
num: '',
opp: 'TEN 8th',
game_time: 'SE',
dk_sal: 3500,
dk_pts: 2.4,
fd_sal: 4700,
fd_pts: 2.2,
y_sal: 10,
y_pts: 2.2,
rating: 'None',
},
{
id: 1500,
name: 'Randall Cobb',
team: 'DAL',
pos: 'WR',
num: '#18',
opp: 'NO 30th',
game_time: 'SN',
dk_sal: 4400,
dk_pts: 9,
fd_sal: 5100,
fd_pts: 7.2,
y_sal: 15,
y_pts: 7.2,
rating: 'None',
},
{
id: 1501,
name: 'Rashaad Penny',
team: 'SEA',
pos: 'RB',
num: '#20',
opp: 'ARI 14th',
game_time: 'SL',
dk_sal: 4200,
dk_pts: 7,
fd_sal: 5900,
fd_pts: 6.6,
y_sal: 13,
y_pts: 6.6,
rating: 'None',
},
{
id: 1502,
name: 'Rashard Higgins',
team: 'CLE',
pos: 'WR',
num: '#81',
opp: 'BAL 23rd',
game_time: 'SE',
dk_sal: 3400,
dk_pts: 6.7,
fd_sal: 4500,
fd_pts: 5.6,
y_sal: 12,
y_pts: 5.6,
rating: 'None',
},
{
id: 1503,
name: 'Ray-Ray McCloud',
team: 'CAR',
pos: 'WR',
num: '',
opp: 'HOU 24th',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 0.1,
fd_sal: 4500,
fd_pts: 0.1,
y_sal: 10,
y_pts: 0.1,
rating: 'None',
},
{
id: 1504,
name: 'Reggie Bonnafon',
team: 'CAR',
pos: 'RB',
num: '',
opp: 'HOU 10th',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 0.9,
fd_sal: 4500,
fd_pts: 0.8,
y_sal: 10,
y_pts: 0.8,
rating: 'None',
},
{
id: 1506,
name: 'Rex Burkhead',
team: 'NE',
pos: 'RB',
num: '#34',
opp: 'BUF 18th',
game_time: 'SE',
dk_sal: 4400,
dk_pts: 10.1,
fd_sal: 5600,
fd_pts: 8.7,
y_sal: 16,
y_pts: 8.7,
rating: 'None',
},
{
id: 1507,
name: 'Rhett Ellison',
team: 'NYG',
pos: 'TE',
num: '#85',
opp: 'WAS 14th',
game_time: 'SE',
dk_sal: 2600,
dk_pts: 2.8,
fd_sal: 4000,
fd_pts: 2.3,
y_sal: 10,
y_pts: 2.3,
rating: 'None',
},
{
id: 1508,
name: 'Ricky Seals-Jones',
team: 'CLE',
pos: 'TE',
num: '#86',
opp: 'BAL 10th',
game_time: 'SE',
dk_sal: 2800,
dk_pts: 2.8,
fd_sal: 5000,
fd_pts: 2.3,
y_sal: 10,
y_pts: 2.3,
rating: 'None',
},
{
id: 1509,
name: 'Riley Ridley',
team: 'CHI',
pos: 'WR',
num: '',
opp: 'MIN 20th',
game_time: 'SL',
dk_sal: 3000,
dk_pts: 0.9,
fd_sal: 4500,
fd_pts: 0.7,
y_sal: 10,
y_pts: 0.7,
rating: 'None',
},
{
id: 1511,
name: 'Robert Foster',
team: 'BUF',
pos: 'WR',
num: '#16',
opp: 'NE 1st',
game_time: 'SE',
dk_sal: 3100,
dk_pts: 1.3,
fd_sal: 4500,
fd_pts: 1.1,
y_sal: 10,
y_pts: 1.1,
rating: 'None',
},
{
id: 1512,
name: 'Robert Griffin III',
team: 'BAL',
pos: 'QB',
num: '#3',
opp: 'CLE 11th',
game_time: 'SE',
dk_sal: 4100,
dk_pts: 0.6,
fd_sal: 6000,
fd_pts: 0.6,
y_sal: 20,
y_pts: 0.6,
rating: 'None',
},
{
id: 1513,
name: 'Robert Tonyan Jr',
team: 'GB',
pos: 'TE',
num: '',
opp: 'PHI 13th',
game_time: 'T',
dk_sal: 2500,
dk_pts: 2.7,
fd_sal: 4000,
fd_pts: 2.2,
y_sal: 10,
y_pts: 2.2,
rating: 'None',
},
{
id: 1514,
name: 'Robert Woods',
team: 'LAR',
pos: 'WR',
num: '#17',
opp: 'TB 12th',
game_time: 'SL',
dk_sal: 6100,
dk_pts: 15.4,
fd_sal: 7100,
fd_pts: 12.9,
y_sal: 16,
y_pts: 12.9,
rating: 'None',
},
{
id: 1515,
name: 'Ronald Jones II',
team: 'TB',
pos: 'RB',
num: '#27',
opp: 'LAR 23rd',
game_time: 'SL',
dk_sal: 4400,
dk_pts: 6.4,
fd_sal: 5100,
fd_pts: 5.8,
y_sal: 14,
y_pts: 5.8,
rating: 'None',
},
{
id: 1516,
name: 'Roosevelt Nix',
team: 'PIT',
pos: 'RB',
num: '',
opp: 'CIN 31st',
game_time: 'M',
dk_sal: 3000,
dk_pts: 0.2,
fd_sal: 4500,
fd_pts: 0.2,
y_sal: 10,
y_pts: 0.2,
rating: 'None',
},
{
id: 1517,
name: 'Royce Freeman',
team: 'DEN',
pos: 'RB',
num: '#28',
opp: 'JAX 13th',
game_time: 'SL',
dk_sal: 4800,
dk_pts: 10.2,
fd_sal: 5500,
fd_pts: 9.2,
y_sal: 17,
y_pts: 9.2,
rating: 'None',
},
{
id: 1518,
name: 'Russell Gage',
team: 'ATL',
pos: 'WR',
num: '',
opp: 'TEN 10th',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 1.1,
fd_sal: 4500,
fd_pts: 0.9,
y_sal: 10,
y_pts: 0.9,
rating: 'None',
},
{
id: 1519,
name: 'Russell Shepard',
team: 'NYG',
pos: 'WR',
num: '#81',
opp: 'WAS 31st',
game_time: 'SE',
dk_sal: 3100,
dk_pts: 3.5,
fd_sal: 4500,
fd_pts: 2.8,
y_sal: 10,
y_pts: 2.8,
rating: 'None',
},
{
id: 1520,
name: 'Russell Wilson',
team: 'SEA',
pos: 'QB',
num: '#3',
opp: 'ARI 30th',
game_time: 'SL',
dk_sal: 6100,
dk_pts: 20.9,
fd_sal: 7800,
fd_pts: 20.8,
y_sal: 33,
y_pts: 20.8,
rating: 'None',
},
{
id: 1521,
name: 'Ryan Finley',
team: 'CIN',
pos: 'QB',
num: '',
opp: 'PIT 25th',
game_time: 'M',
dk_sal: 4100,
dk_pts: 0.5,
fd_sal: 6000,
fd_pts: 0.5,
y_sal: 20,
y_pts: 0.5,
rating: 'None',
},
{
id: 1522,
name: 'Ryan Fitzpatrick',
team: 'MIA',
pos: 'QB',
num: '#14',
opp: 'LAC 21st',
game_time: 'SE',
dk_sal: 4400,
dk_pts: 0.8,
fd_sal: 6000,
fd_pts: 0.8,
y_sal: 20,
y_pts: 0.8,
rating: 'None',
},
{
id: 1523,
name: 'Ryan Grant',
team: 'OAK',
pos: 'WR',
num: '#19',
opp: 'IND 8th',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 4.4,
fd_sal: 4500,
fd_pts: 3.4,
y_sal: 10,
y_pts: 3.4,
rating: 'None',
},
{
id: 1525,
name: 'Ryan Izzo',
team: 'NE',
pos: 'TE',
num: '',
opp: 'BUF 3rd',
game_time: 'SE',
dk_sal: 2500,
dk_pts: 2.3,
fd_sal: 4500,
fd_pts: 1.9,
y_sal: 10,
y_pts: 1.9,
rating: 'None',
},
{
id: 1527,
name: 'Ryan Switzer',
team: 'PIT',
pos: 'WR',
num: '#10',
opp: 'CIN 14th',
game_time: 'M',
dk_sal: 3100,
dk_pts: 3.1,
fd_sal: 4500,
fd_pts: 2.5,
y_sal: 10,
y_pts: 2.5,
rating: 'None',
},
{
id: 1528,
name: 'Ryan Tannehill',
team: 'TEN',
pos: 'QB',
num: '#17',
opp: 'ATL 16th',
game_time: 'SE',
dk_sal: 4300,
dk_pts: 0.6,
fd_sal: 6000,
fd_pts: 0.6,
y_sal: 20,
y_pts: 0.6,
rating: 'None',
},
{
id: 1529,
name: 'Ryquell Armstead',
team: 'JAX',
pos: 'RB',
num: '',
opp: 'DEN 27th',
game_time: 'SL',
dk_sal: 3300,
dk_pts: 1.7,
fd_sal: 4500,
fd_pts: 1.4,
y_sal: 10,
y_pts: 1.4,
rating: 'None',
},
{
id: 1530,
name: 'Samaje Perine',
team: 'CIN',
pos: 'RB',
num: '',
opp: 'PIT 30th',
game_time: 'M',
dk_sal: 3500,
dk_pts: 0.4,
fd_sal: 4500,
fd_pts: 0.4,
y_sal: 10,
y_pts: 0.4,
rating: 'None',
},
{
id: 1531,
name: 'Sammy Watkins',
team: 'KC',
pos: 'WR',
num: '#14',
opp: 'DET 22nd',
game_time: 'SE',
dk_sal: 6700,
dk_pts: 16.5,
fd_sal: 6900,
fd_pts: 14,
y_sal: 24,
y_pts: 14,
rating: 'None',
},
{
id: 1534,
name: 'Sean Culkin',
team: 'LAC',
pos: 'TE',
num: '',
opp: 'MIA 29th',
game_time: 'SE',
dk_sal: 2500,
dk_pts: 1.4,
fd_sal: 4000,
fd_pts: 1.3,
y_sal: 10,
y_pts: 1.3,
rating: 'None',
},
{
id: 1535,
name: 'Sean Mannion',
team: 'MIN',
pos: 'QB',
num: '',
opp: 'CHI 7th',
game_time: 'SL',
dk_sal: 4200,
dk_pts: 0.2,
fd_sal: 6000,
fd_pts: 0.2,
y_sal: 20,
y_pts: 0.2,
rating: 'None',
},
{
id: 1537,
name: 'Seahawks',
team: 'SEA',
pos: 'DST',
num: '',
opp: 'ARI 25th',
game_time: 'SL',
dk_sal: 3300,
dk_pts: 8.8,
fd_sal: 3700,
fd_pts: 8.8,
y_sal: 18,
y_pts: 8.8,
rating: 'None',
},
{
id: 1538,
name: 'Seth DeValve',
team: 'JAX',
pos: 'TE',
num: '',
opp: 'DEN 11th',
game_time: 'SL',
dk_sal: 2500,
dk_pts: 0.1,
fd_sal: 4000,
fd_pts: 0.1,
y_sal: 10,
y_pts: 0.1,
rating: 'None',
},
{
id: 1539,
name: 'Seth Roberts',
team: 'BAL',
pos: 'WR',
num: '#11',
opp: 'CLE 11th',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 4.3,
fd_sal: 4500,
fd_pts: 3.5,
y_sal: 10,
y_pts: 3.5,
rating: 'None',
},
{
id: 1541,
name: 'Sony Michel',
team: 'NE',
pos: 'RB',
num: '#26',
opp: 'BUF 18th',
game_time: 'SE',
dk_sal: 5500,
dk_pts: 10.9,
fd_sal: 6100,
fd_pts: 10.5,
y_sal: 19,
y_pts: 10.5,
rating: 'None',
},
{
id: 1543,
name: 'Stefon Diggs',
team: 'MIN',
pos: 'WR',
num: '#14',
opp: 'CHI 15th',
game_time: 'SL',
dk_sal: 5500,
dk_pts: 12.9,
fd_sal: 6100,
fd_pts: 10.4,
y_sal: 18,
y_pts: 10.4,
rating: 'None',
},
{
id: 1546,
name: 'Sterling Shepard',
team: 'NYG',
pos: 'WR',
num: '#87',
opp: 'WAS 31st',
game_time: 'SE',
dk_sal: 5800,
dk_pts: 14.2,
fd_sal: 6500,
fd_pts: 11.7,
y_sal: 17,
y_pts: 11.7,
rating: 'None',
},
{
id: 1548,
name: 'Steven Sims Jr',
team: 'WAS',
pos: 'WR',
num: '',
opp: 'NYG 32nd',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 2.6,
fd_sal: 4500,
fd_pts: 2.2,
y_sal: 10,
y_pts: 2.2,
rating: 'None',
},
{
id: 1549,
name: 'TJ Hockenson',
team: 'DET',
pos: 'TE',
num: '#88',
opp: 'KC 20th',
game_time: 'SE',
dk_sal: 3300,
dk_pts: 8.9,
fd_sal: 5200,
fd_pts: 7.3,
y_sal: 18,
y_pts: 7.3,
rating: 'None',
},
{
id: 1550,
name: 'TY Hilton',
team: 'IND',
pos: 'WR',
num: '#13',
opp: 'OAK 27th',
game_time: 'SE',
dk_sal: 6400,
dk_pts: 11.8,
fd_sal: 7400,
fd_pts: 9.8,
y_sal: 29,
y_pts: 9.8,
rating: 'None',
},
{
id: 1551,
name: 'Taiwan Jones',
team: 'HOU',
pos: 'RB',
num: '',
opp: 'CAR 22nd',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 0.5,
fd_sal: 4500,
fd_pts: 0.4,
y_sal: 10,
y_pts: 0.4,
rating: 'None',
},
{
id: 1552,
name: 'Tajae Sharpe',
team: 'TEN',
pos: 'WR',
num: '#19',
opp: 'ATL 12th',
game_time: 'SE',
dk_sal: 3200,
dk_pts: 4.1,
fd_sal: 4800,
fd_pts: 3.3,
y_sal: 10,
y_pts: 3.3,
rating: 'None',
},
{
id: 1553,
name: 'Buccaneers',
team: 'TB',
pos: 'DST',
num: '',
opp: 'LAR 16th',
game_time: 'SL',
dk_sal: 2100,
dk_pts: 4.8,
fd_sal: 3200,
fd_pts: 4.8,
y_sal: 15,
y_pts: 4.8,
rating: 'None',
},
{
id: 1556,
name: 'Tarik Cohen',
team: 'CHI',
pos: 'RB',
num: '#29',
opp: 'MIN 9th',
game_time: 'SL',
dk_sal: 4600,
dk_pts: 11.1,
fd_sal: 5800,
fd_pts: 8.8,
y_sal: 13,
y_pts: 8.8,
rating: 'None',
},
{
id: 1557,
name: 'Tavon Austin',
team: 'DAL',
pos: 'WR',
num: '#10',
opp: 'NO 30th',
game_time: 'SN',
dk_sal: 3000,
dk_pts: 2.2,
fd_sal: 4500,
fd_pts: 1.8,
y_sal: 10,
y_pts: 1.8,
rating: 'None',
},
{
id: 1558,
name: 'Taylor Gabriel',
team: 'CHI',
pos: 'WR',
num: '#18',
opp: 'MIN 20th',
game_time: 'SL',
dk_sal: 3700,
dk_pts: 7.7,
fd_sal: 4800,
fd_pts: 6.3,
y_sal: 10,
y_pts: 6.3,
rating: 'None',
},
{
id: 1559,
name: 'Taysom Hill',
team: 'NO',
pos: 'QB',
num: '',
opp: 'DAL 9th',
game_time: 'SN',
dk_sal: 4300,
dk_pts: 2.3,
fd_sal: 6000,
fd_pts: 2.2,
y_sal: 20,
y_pts: 2.2,
rating: 'None',
},
{
id: 1560,
name: 'Taywan Taylor',
team: 'CLE',
pos: 'WR',
num: '#13',
opp: 'BAL 23rd',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 0.8,
fd_sal: 4500,
fd_pts: 0.7,
y_sal: 10,
y_pts: 0.7,
rating: 'None',
},
{
id: 1561,
name: 'Ted Ginn',
team: 'NO',
pos: 'WR',
num: '#29',
opp: 'DAL 6th',
game_time: 'SN',
dk_sal: 3800,
dk_pts: 8,
fd_sal: 5000,
fd_pts: 6.6,
y_sal: 13,
y_pts: 6.6,
rating: 'None',
},
{
id: 1562,
name: 'Teddy Bridgewater',
team: 'NO',
pos: 'QB',
num: '#5',
opp: 'DAL 9th',
game_time: 'SN',
dk_sal: 5200,
dk_pts: 15.5,
fd_sal: 7100,
fd_pts: 15.5,
y_sal: 26,
y_pts: 15.5,
rating: 'None',
},
{
id: 1564,
name: 'Titans',
team: 'TEN',
pos: 'DST',
num: '',
opp: 'ATL 10th',
game_time: 'SE',
dk_sal: 2600,
dk_pts: 6.5,
fd_sal: 3500,
fd_pts: 6.5,
y_sal: 12,
y_pts: 6.5,
rating: 'None',
},
{
id: 1565,
name: 'Terry McLaurin',
team: 'WAS',
pos: 'WR',
num: '#17',
opp: 'NYG 32nd',
game_time: 'SE',
dk_sal: 4500,
dk_pts: 14.2,
fd_sal: 6300,
fd_pts: 11.9,
y_sal: 23,
y_pts: 11.9,
rating: 'None',
},
{
id: 1571,
name: 'TJ Jones',
team: 'NYG',
pos: 'WR',
num: '',
opp: 'WAS 31st',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 2.6,
fd_sal: 4700,
fd_pts: 2.1,
y_sal: 10,
y_pts: 2.1,
rating: 'None',
},
{
id: 1572,
name: 'TJ Logan',
team: 'TB',
pos: 'RB',
num: '',
opp: 'LAR 23rd',
game_time: 'SL',
dk_sal: 3000,
dk_pts: 1.3,
fd_sal: 4500,
fd_pts: 1,
y_sal: 10,
y_pts: 1,
rating: 'None',
},
{
id: 1573,
name: 'TJ Yeldon',
team: 'BUF',
pos: 'RB',
num: '#22',
opp: 'NE 1st',
game_time: 'SE',
dk_sal: 3700,
dk_pts: 4,
fd_sal: 4600,
fd_pts: 3.5,
y_sal: 12,
y_pts: 3.5,
rating: 'None',
},
{
id: 1574,
name: 'Todd Gurley',
team: 'LAR',
pos: 'RB',
num: '#30',
opp: 'TB 2nd',
game_time: 'SL',
dk_sal: 7000,
dk_pts: 16.7,
fd_sal: 6800,
fd_pts: 15.4,
y_sal: 26,
y_pts: 15.4,
rating: 'None',
},
{
id: 1575,
name: 'Tom Brady',
team: 'NE',
pos: 'QB',
num: '#12',
opp: 'BUF 5th',
game_time: 'SE',
dk_sal: 6600,
dk_pts: 18.4,
fd_sal: 7800,
fd_pts: 18.4,
y_sal: 32,
y_pts: 18.4,
rating: 'None',
},
{
id: 1577,
name: 'Tommy Sweeney',
team: 'BUF',
pos: 'TE',
num: '',
opp: 'NE 4th',
game_time: 'SE',
dk_sal: 2500,
dk_pts: 2.5,
fd_sal: 4000,
fd_pts: 2.1,
y_sal: 10,
y_pts: 2.1,
rating: 'None',
},
{
id: 1579,
name: 'Tony Pollard',
team: 'DAL',
pos: 'RB',
num: '#88',
opp: 'NO 6th',
game_time: 'SN',
dk_sal: 4600,
dk_pts: 5.9,
fd_sal: 5000,
fd_pts: 5.3,
y_sal: 12,
y_pts: 5.3,
rating: 'None',
},
{
id: 1582,
name: 'Travis Benjamin',
team: 'LAC',
pos: 'WR',
num: '#12',
opp: 'MIA 28th',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 4.1,
fd_sal: 4500,
fd_pts: 3.4,
y_sal: 10,
y_pts: 3.4,
rating: 'None',
},
{
id: 1585,
name: 'Travis Kelce',
team: 'KC',
pos: 'TE',
num: '#87',
opp: 'DET 6th',
game_time: 'SE',
dk_sal: 7200,
dk_pts: 18.6,
fd_sal: 7600,
fd_pts: 15.4,
y_sal: 28,
y_pts: 15.4,
rating: 'None',
},
{
id: 1587,
name: 'Trent Sherfield',
team: 'ARI',
pos: 'WR',
num: '#16',
opp: 'SEA 9th',
game_time: 'SL',
dk_sal: 3000,
dk_pts: 1.6,
fd_sal: 4500,
fd_pts: 1.2,
y_sal: 10,
y_pts: 1.2,
rating: 'None',
},
{
id: 1588,
name: 'TreQuan Smith',
team: 'NO',
pos: 'WR',
num: '#10',
opp: 'DAL 6th',
game_time: 'SN',
dk_sal: 3800,
dk_pts: 6.8,
fd_sal: 5200,
fd_pts: 5.8,
y_sal: 11,
y_pts: 5.8,
rating: 'None',
},
{
id: 1589,
name: 'Trevor Davis',
team: 'OAK',
pos: 'WR',
num: '',
opp: 'IND 8th',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 1.1,
fd_sal: 4500,
fd_pts: 0.9,
y_sal: 10,
y_pts: 0.9,
rating: 'None',
},
{
id: 1590,
name: 'Trey Burton',
team: 'CHI',
pos: 'TE',
num: '#80',
opp: 'MIN 18th',
game_time: 'SL',
dk_sal: 3800,
dk_pts: 6.6,
fd_sal: 4900,
fd_pts: 5.3,
y_sal: 12,
y_pts: 5.3,
rating: 'None',
},
{
id: 1592,
name: 'Trey Quinn',
team: 'WAS',
pos: 'WR',
num: '#14',
opp: 'NYG 32nd',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 8.6,
fd_sal: 5400,
fd_pts: 7,
y_sal: 13,
y_pts: 7,
rating: 'None',
},
{
id: 1593,
name: 'Troy Fumagalli',
team: 'DEN',
pos: 'TE',
num: '',
opp: 'JAX 17th',
game_time: 'SL',
dk_sal: 2500,
dk_pts: 1.2,
fd_sal: 4000,
fd_pts: 1.1,
y_sal: 10,
y_pts: 1.1,
rating: 'None',
},
{
id: 1594,
name: 'Troymaine Pope',
team: 'LAC',
pos: 'RB',
num: '',
opp: 'MIA 32nd',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 0.2,
fd_sal: 4500,
fd_pts: 0.2,
y_sal: 10,
y_pts: 0.2,
rating: 'None',
},
{
id: 1595,
name: 'Ty Johnson',
team: 'DET',
pos: 'RB',
num: '#31',
opp: 'KC 23rd',
game_time: 'SE',
dk_sal: 3000,
dk_pts: 6.8,
fd_sal: 4500,
fd_pts: 6,
y_sal: 10,
y_pts: 6,
rating: 'None',
},
{
id: 1596,
name: 'Tyler Boyd',
team: 'CIN',
pos: 'WR',
num: '#83',
opp: 'PIT 26th',
game_time: 'M',
dk_sal: 6200,
dk_pts: 15.7,
fd_sal: 6300,
fd_pts: 12.7,
y_sal: 23,
y_pts: 12.7,
rating: 'None',
},
{
id: 1598,
name: 'Tyler Conklin',
team: 'MIN',
pos: 'TE',
num: '',
opp: 'CHI 21st',
game_time: 'SL',
dk_sal: 2500,
dk_pts: 0.6,
fd_sal: 4000,
fd_pts: 0.5,
y_sal: 10,
y_pts: 0.5,
rating: 'None',
},
{
id: 1599,
name: 'Tyler Eifert',
team: 'CIN',
pos: 'TE',
num: '#85',
opp: 'PIT 22nd',
game_time: 'M',
dk_sal: 3200,
dk_pts: 6.2,
fd_sal: 4600,
fd_pts: 5,
y_sal: 14,
y_pts: 5,
rating: 'None',
},
{
id: 1600,
name: 'Tyler Ervin',
team: 'JAX',
pos: 'RB',
num: '#39',
opp: 'DEN 27th',
game_time: 'SL',
dk_sal: 3000,
dk_pts: 0.1,
fd_sal: 4500,
fd_pts: 0.1,
y_sal: 10,
y_pts: 0.1,
rating: 'None',
},
{
id: 1601,
name: 'Tyler Higbee',
team: 'LAR',
pos: 'TE',
num: '#89',
opp: 'TB 30th',
game_time: 'SL',
dk_sal: 3200,
dk_pts: 4.6,
fd_sal: 5000,
fd_pts: 3.8,
y_sal: 13,
y_pts: 3.8,
rating: 'None',
},
{
id: 1602,
name: 'Tyler Kroft',
team: 'BUF',
pos: 'TE',
num: '',
opp: 'NE 4th',
game_time: 'SE',
dk_sal: 2600,
dk_pts: 2.7,
fd_sal: 4000,
fd_pts: 2.2,
y_sal: 10,
y_pts: 2.2,
rating: 'None',
},
{
id: 1603,
name: 'Tyler Lockett',
team: 'SEA',
pos: 'WR',
num: '#16',
opp: 'ARI 16th',
game_time: 'SL',
dk_sal: 6300,
dk_pts: 15.2,
fd_sal: 6600,
fd_pts: 12.7,
y_sal: 23,
y_pts: 12.7,
rating: 'None',
},
{
id: 1605,
name: 'Tyrell Williams',
team: 'OAK',
pos: 'WR',
num: '#16',
opp: 'IND 8th',
game_time: 'SE',
dk_sal: 5700,
dk_pts: 12.4,
fd_sal: 5900,
fd_pts: 10.3,
y_sal: 17,
y_pts: 10.3,
rating: 'None',
},
{
id: 1606,
name: 'Tyrod Taylor',
team: 'LAC',
pos: 'QB',
num: '#5',
opp: 'MIA 31st',
game_time: 'SE',
dk_sal: 4100,
dk_pts: 0.7,
fd_sal: 6000,
fd_pts: 0.7,
y_sal: 20,
y_pts: 0.7,
rating: 'None',
},
{
id: 1608,
name: 'Vance McDonald',
team: 'PIT',
pos: 'TE',
num: '#89',
opp: 'CIN 19th',
game_time: 'M',
dk_sal: 4000,
dk_pts: 8.4,
fd_sal: 5500,
fd_pts: 6.8,
y_sal: 15,
y_pts: 6.8,
rating: 'None',
},
{
id: 1610,
name: 'Vernon Davis',
team: 'WAS',
pos: 'TE',
num: '#85',
opp: 'NYG 26th',
game_time: 'SE',
dk_sal: 3400,
dk_pts: 8.2,
fd_sal: 5100,
fd_pts: 6.8,
y_sal: 14,
y_pts: 6.8,
rating: 'None',
},
{
id: 1611,
name: 'Virgil Green',
team: 'LAC',
pos: 'TE',
num: '#88',
opp: 'MIA 29th',
game_time: 'SE',
dk_sal: 2600,
dk_pts: 2.9,
fd_sal: 4000,
fd_pts: 2.4,
y_sal: 13,
y_pts: 2.4,
rating: 'None',
},
{
id: 1612,
name: 'Redskins',
team: 'WAS',
pos: 'DST',
num: '',
opp: 'NYG 7th',
game_time: 'SE',
dk_sal: 2800,
dk_pts: 7.5,
fd_sal: 4100,
fd_pts: 7.5,
y_sal: 10,
y_pts: 7.5,
rating: 'None',
},
{
id: 1613,
name: 'Wayne Gallman',
team: 'NYG',
pos: 'RB',
num: '#22',
opp: 'WAS 11th',
game_time: 'SE',
dk_sal: 4600,
dk_pts: 10.7,
fd_sal: 5800,
fd_pts: 9.6,
y_sal: 10,
y_pts: 9.6,
rating: 'None',
},
{
id: 1614,
name: 'Wendell Smallwood',
team: 'WAS',
pos: 'RB',
num: '#28',
opp: 'NYG 25th',
game_time: 'SE',
dk_sal: 3100,
dk_pts: 2.3,
fd_sal: 4500,
fd_pts: 2,
y_sal: 10,
y_pts: 2,
rating: 'None',
},
{
id: 1615,
name: 'Will Dissly',
team: 'SEA',
pos: 'TE',
num: '#88',
opp: 'ARI 32nd',
game_time: 'SL',
dk_sal: 3600,
dk_pts: 9.4,
fd_sal: 5400,
fd_pts: 7.8,
y_sal: 18,
y_pts: 7.8,
rating: 'None',
},
{
id: 1616,
name: 'Will Fuller',
team: 'HOU',
pos: 'WR',
num: '#15',
opp: 'CAR 7th',
game_time: 'SE',
dk_sal: 4500,
dk_pts: 11.2,
fd_sal: 5900,
fd_pts: 9.3,
y_sal: 16,
y_pts: 9.3,
rating: 'None',
},
{
id: 1617,
name: 'Will Grier',
team: 'CAR',
pos: 'QB',
num: '',
opp: 'HOU 27th',
game_time: 'SE',
dk_sal: 4000,
dk_pts: 0.2,
fd_sal: 6000,
fd_pts: 0.2,
y_sal: 20,
y_pts: 0.2,
rating: 'None',
},
{
id: 1618,
name: 'Willie Snead',
team: 'BAL',
pos: 'WR',
num: '#83',
opp: 'CLE 11th',
game_time: 'SE',
dk_sal: 4400,
dk_pts: 7.7,
fd_sal: 5000,
fd_pts: 6.2,
y_sal: 11,
y_pts: 6.2,
rating: 'None',
},
{
id: 1619,
name: 'Xavier Grimble',
team: 'PIT',
pos: 'TE',
num: '',
opp: 'CIN 19th',
game_time: 'M',
dk_sal: 2800,
dk_pts: 2.7,
fd_sal: 4000,
fd_pts: 2,
y_sal: 10,
y_pts: 2,
rating: 'None',
},
{
id: 1620,
name: 'Zach Ertz',
team: 'PHI',
pos: 'TE',
num: '#86',
opp: 'GB 1st',
game_time: 'T',
dk_sal: 6200,
dk_pts: 14.3,
fd_sal: 6600,
fd_pts: 11.5,
y_sal: 19,
y_pts: 11.5,
rating: 'None',
},
{
id: 1621,
name: 'Zach Gentry',
team: 'PIT',
pos: 'TE',
num: '',
opp: 'CIN 19th',
game_time: 'M',
dk_sal: 2500,
dk_pts: 1.3,
fd_sal: 4000,
fd_pts: 1.1,
y_sal: 10,
y_pts: 1.1,
rating: 'None',
},
{
id: 1622,
name: 'Zach Line',
team: 'NO',
pos: 'RB',
num: '',
opp: 'DAL 12th',
game_time: 'SN',
dk_sal: 3000,
dk_pts: 0.5,
fd_sal: 4500,
fd_pts: 0.4,
y_sal: 10,
y_pts: 0.4,
rating: 'None',
},
{
id: 1623,
name: 'Zach Pascal',
team: 'IND',
pos: 'WR',
num: '#14',
opp: 'OAK 27th',
game_time: 'SE',
dk_sal: 4500,
dk_pts: 3.5,
fd_sal: 5400,
fd_pts: 2.9,
y_sal: 11,
y_pts: 2.9,
rating: 'None',
},
{
id: 1624,
name: 'Zay Jones',
team: 'BUF',
pos: 'WR',
num: '#11',
opp: 'NE 1st',
game_time: 'SE',
dk_sal: 3100,
dk_pts: 6,
fd_sal: 4700,
fd_pts: 4.9,
y_sal: 10,
y_pts: 4.9,
rating: 'None',
},
]

export default players
